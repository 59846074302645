import { Suspense, useRef, useState } from 'react'
import { Canvas, useFrame, useLoader, useThree } from '@react-three/fiber'
import {
    OrbitControls, Environment, useGLTF, PresentationControls,
    ContactShadows, Plane, MeshRefractionMaterial
} from '@react-three/drei'
import {
    PlusCircle, MinusCircle, RotateCcw, RotateCw,
    Wrench, ZoomIn, ZoomOut, Save, Video, VideoOff,
    ImageDown, Sparkles, Rotate3D, ImagePlus, Sliders, Plus, Settings
} from 'lucide-react';
// import { MeshRefractionMaterial } from "@react-three/drei/materials/MeshRefractionMaterial";

import { RGBELoader, VertexNormalsHelper } from 'three-stdlib'
import Loader from "react-js-loader";
import Colors from './Colors';
import { useEffect } from 'react';
import {
    EffectComposer, Bloom, N8AO,
    ToneMapping, Noise
} from '@react-three/postprocessing'
import * as THREE from 'three';
import SaveConfigPopup from './SaveConfigPopup.jsx'
import { BASE_URL } from '../lib/commons.js'
import ConfigListScreen from './ConfigListScreen.jsx';
import { useParams } from 'react-router-dom';
import GenerateScreen from './GenerateScreen.jsx';
import ClosePopup from './ClosePopup.jsx';

import { Button } from 'react-bootstrap';
import { useAuth } from "react-oidc-context";
import { normalize } from 'three/src/math/MathUtils.js';
import { vec4 } from 'three/examples/jsm/nodes/shadernode/ShaderNode.js';

// Constants and variables
let diamonds = [];
const bgColors = ['#FFFFFF', '#28282B', '#000000'];
// const ringColors = ['#ebcf9a', '#c3c3c3', '#c8c8c8', '#d8d8d8', '#ebc4af'];
const ringColors = ['#ebcf9a', '#c3c3c3', '#c8c8c8'];
const diamondColors = ['#E0115F', '#4cb3a0', '#FFFFFF'];
let metal_colors_map = {
    '#ebcf9a': 'Yellow Gold',
    '#c3c3c3': 'Platinum',
    '#c8c8c8': 'Silver',
    '#d8d8d8': 'White Gold',
    '#ebc4af': 'Rose Gold'
};

let diamond_colors_map = {
    '#E0115F': 'Ruby',
    '#4cb3a0': 'Green',
    '#FFFFFF': 'Natural',
};

function Loading() {
    return <div className='loading'>
        <Loader type="bubble-loop" bgColor='black' color='black' title={""} size={80} />
    </div>
}

function GradientPlane(props) {
    if (props.hex === '#000000') {
        return <>

        </>
    }
    return <Plane args={[50, 50]} rotation={[0, 0, 0]} position={[0, 0, -10]} receiveShadow>
        <meshStandardMaterial attach='material' map={createGradientTexture(props.hex)} />
    </Plane>
}

const createGradientTexture = (hex) => {
    const canvas = document.createElement('canvas');
    canvas.width = 256;
    canvas.height = 256;
    const context = canvas.getContext('2d');
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    const gradient = context.createRadialGradient(
        centerX,
        centerY,
        0,
        centerX,
        centerY,
        canvas.width / 2
    );

    gradient.addColorStop(0, hex);
    gradient.addColorStop(0.35, hex);

    context.fillStyle = gradient;
    context.fillRect(0, 0, canvas.width, canvas.height);

    const texture = new THREE.CanvasTexture(canvas);
    return texture;
};

function Diamond(props) {
    // const dref = useRef();
    //     if(dref.current) {dref.current.geometry.computeVertexNormals();
      
    //   // Adding a helper to visualize normals
    //   const helper = new VertexNormalsHelper(dref.current, 1, 0x00ff00);
    //   dref.current.add(helper);
    // //   dref.current.geometry.scale(-1, 1, 1);
    // }

    // const { scene } = useThree();
    // if (dref.current) {
    //     const geometry = dref.current.geometry;
  
    //     // Recompute vertex normals
    //     geometry.computeVertexNormals();
  
    //     // Determine the average normal direction
    //     const normals = geometry.attributes.normal.array;
    //     let averageNormal = new THREE.Vector3(0, 0, 0);
  
    //     for (let i = 0; i < normals.length; i += 3) {
    //       const normal = new THREE.Vector3(normals[i], normals[i + 1], normals[i + 2]);
    //       averageNormal.add(normal);
    //     }
  
    //     averageNormal.normalize();
  
    //     // Check if average normal points inward
    //     if (averageNormal.dot(new THREE.Vector3(0, 0, 1)) < 0) {
    //       // Flip normals if they point inward
    //       geometry.scale(-1, 1, 1); // Invert the X axis
    //       geometry.computeVertexNormals(); // Recompute normals after scaling
    //     }
  
    //     // Adding a helper to visualize normals
    //     const helper = new VertexNormalsHelper(dref.current, 0.1, 0x00ff00);
    //     scene.add(helper);
    //   }

    return <mesh  {...props} castShadow>
        <MeshRefractionMaterial
            color={props.color}
            envMap={props.texture}
            toneMapped={true}
            // side={THREE.DoubleSide}
        />
    </mesh>
}

// Main funtion

const RenderScreen_bkp = ({userRoles}) => {

    const auth = useAuth();
    const [userData, setUserData] = useState(null);

    const [canCreate, setCanCreate] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [canDelete, setCanDelete] = useState(false);

    const { styleNo } = useParams();
    // State and Ref variables
    const [showConfigPopup, setShowConfigPopup] = useState(false);
    const [showClosePopup, setShowClosePopup] = useState(false);

    const [isGenerating, setIsGenerating] = useState(false);

    const [hex, setHex] = useState('#28282B');
    const [ringHex, setRingHex] = useState('#c8c8c8');
    const [diamondHex, setDiamondHex] = useState('#FFFFFF');

    const meshGl = useRef(null);
    const meshCamera = useRef(null);
    const meshScene = useRef(null);
    const canvasRef = useRef();
    const mediaRecorderRef = useRef();
    const chunksRef = useRef([]);

    const [modelUrl, setModelUrl] = useState(null);
    const [ProdHanles, setProdHanles] = useState([]);
    const [currProdHandle, setcurrProdHandle] = useState('vida-3-dia');

    const baseNode = useRef();
    const [isBloom, setIsBloom] = useState(false);
    const [customRingRotationX, setCustomRingRotationX] = useState(-0.3);
    const [customDiamondRotationX, setcustomDiamondRotationX] = useState(1.2707);
    const [rotationConstantX, setRotationConstantX] = useState(0);
    const [rotationConstantY, setRotationConstantY] = useState(0);
    const [rotationConstantZ, setRotationConstantZ] = useState(0);
    const [cPlaneRotation, setCPlaneRotation] = useState(0);
    const [cPlanePosition, setCPlanePosition] = useState(-0.5);
    const [zoomConstant, setZoomConstant] = useState(0);
    const [isRotate, setIsRotate] = useState(false);
    const RingRef = useRef(null);
    const DiamondRef = useRef(null);
    const [advancedControls, setAdvancedControls] = useState(false);
    const [toggleControls, setToggleControls] = useState(false);

    const [configs, setConfigs] = useState([]);
    const [currConfigId, setCurrConfigId] = useState(null);
    const [currConfigName, setCurrConfigName] = useState('Default');
    const [currView, setCurrView] = useState(null);

    const [toggleAlert, setToggleAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const [alertColor, setAlertColor] = useState('green');
    const [currentIndex, setCurrentIndex] = useState(0);
    const [showCanvas, setShowCanvas] = useState(false);
    // const [configIndex, setConfigIndex] = useState(0);
    const configIndex = useRef(1);
    const [collectionName, setCollectionName] = useState(null);
    const [style_numbers, setStyle_numbers] = useState([]);
    const [isVideo, setIsVideo] = useState(false);
    const [arrayOfUrls, setarrayOfUrls] = useState([]);

    // const style_numbers = ['RN-12', 'RN-2', 'RN-123'];

    const alertStyle = {
        position: "absolute",
        top: "0",
        right: "0",
        left: "0",
        zIndex: "1000",
        backgroundColor: alertColor
    }

    function Alerts() {
        return (
            <>
                {toggleAlert && <div className="alert" style={alertStyle}>{alertMessage}</div>}
            </>
        )
    }

    function showAlert(message, color, time = 3000) {
        setAlertMessage(message);
        setAlertColor(color);
        setToggleAlert(true);
        setTimeout(function () { setToggleAlert(false) }, time)
    }


    // API call to get constants 
    useEffect(() => {
        getConfigs();
        // getProducts();
    }, [])



    const saveConfig = async (configName, view) => {
        try {
            const response = await fetch(`${BASE_URL}addConfig`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ configName, backgroundColor: hex, metalColor: ringHex, diamondColor: diamondHex, zoomConstant, rotationConstantX, rotationConstantY, rotationConstantZ, view, 'user_name' : userData?.preferred_username }),
            });
            if (!response.ok) {
                const { success, message } = await response.json();
                showAlert(message, success == "true" ? "lightgreen" : "lightcoral");
            }
            const { success, message } = await response.json();
            showAlert(message, success == "true" ? "lightgreen" : "lightcoral");
            getConfigs();
        } catch (error) {
            showAlert("Something wrong happen while saving the configuration! Try again after some time.", "lightcoral");
        }
        setShowConfigPopup(false);
    }

    const updateConfig = async (configId, configName, view) => {
        try {
            const response = await fetch(`${BASE_URL}editConfig`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ configId, configName, backgroundColor: hex, metalColor: ringHex, diamondColor: diamondHex, zoomConstant, rotationConstantX, rotationConstantY, view, 'user_name' : userData?.preferred_username }),
            });
            if (!response.ok) {
                const { success, message } = await response.json();
                showAlert(message, success == "true" ? "lightgreen" : "lightcoral");
            }
            const { success, message } = await response.json();
            showAlert(message, success == "true" ? "lightgreen" : "lightcoral");
            getConfigs();
        } catch (error) {
            showAlert("Something wrong happen while saving the configuration! Try again after some time.", "lightcoral");
        }
        setShowConfigPopup(false);
    }

    const deleteConfig = async (configName) => {
        try {
            const response = await fetch(`${BASE_URL}deleteConfig`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ configName, 'user_name' : userData?.preferred_username }),
            });
            if (!response.ok) {
                const { success, message } = await response.json();
                showAlert(message, success == "true" ? "lightgreen" : "lightcoral");
            }
            const { success, message } = await response.json();
            showAlert(message, success == "true" ? "lightgreen" : "lightcoral");
            getConfigs();
        } catch (error) {
            showAlert("Something wrong happen while deleting the configuration! Try again after some time.", "lightcoral");
        }
    }

    const setConfig = (config) => {
        setCurrConfigId(config.id);
        setCurrConfigName(config.configName);
        setCurrView(config.view);
        setZoomConstant(config.zoomConstant);
        setRotationConstantX(config.rotationConstantX);
        setRotationConstantY(config.rotationConstantY);
        setRotationConstantZ(config.rotationConstantZ);
        setHex(config.backgroundColor);
        setRingHex(config.metalColor);
        setDiamondHex(config.diamondColor);
    }

    const getConfigs = async () => {
        try {
            const response = await fetch(`${BASE_URL}getConfigs`);
            if (!response.ok) {
                const { success, message } = await response.json();
                showAlert(message, success == "true" ? "lightgreen" : "lightcoral");
            }
            const data = await response.json();
            setConfigs(data);
        } catch (error) {
            showAlert("Something wrong happen while Fetching configurations! Try again after some time.", "lightcoral");
        }
        setShowConfigPopup(false);
    }

    // Local constants
    const NEW_BASE_URL = process.env.REACT_APP_BASE_URL_FILE_SERVER || 'https://j1.engagevida.com/';
    const storeName = 'sj.myshopify.com'
    // const productHandle='vida-13-dia'


    function resetControls() {
        setRotationConstantX(0);
        setRotationConstantY(0);
        setRotationConstantZ(0);
        setZoomConstant(0);
        setHex('#28282B');
        setRingHex('#c8c8c8');
        setDiamondHex('#FFFFFF');
        setCPlaneRotation(0);
        setCPlanePosition(-0.5);
    }

    // API call to get glb file from the SERVER

    const fetch3dModel = async () => {
        console.log('inside fecth3d model');
        try {
            const url = NEW_BASE_URL + `getFile?style_no=${styleNo}`;
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.arrayBuffer();
            const file = new Blob([data], { type: "model/gltf+binary" });
            setModelUrl(URL.createObjectURL(file));
            // console.log(modelUrl);
        } catch (error) {
            // console.error(error);
            return null;
        }
    };
    useEffect(() => {
        fetch3dModel();
    }, []);

    const fetchMultiple3dModel = async (productName) => {
        // console.log('inside fetchMultiple3dModel');
        // console.log(productName);
        try {
            const url = NEW_BASE_URL + `getFile?style_no=${productName}`;
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.arrayBuffer();
            const file = new Blob([data], { type: "model/gltf+binary" });
            setModelUrl(URL.createObjectURL(file));
            // console.log(modelUrl);
        } catch (error) {
            // console.error(error);
            return null;
        }
    };
    const fetchMultiple3dModels = async (style_numbers) => {
        console.log('inside fetchMultiple3dModels()');
        let array_of_urls = [];
        try {
            for(let index = 0; index < style_numbers.length; index+=1) {
                const url = NEW_BASE_URL + `getFile?style_no=${style_numbers[index]}`;
                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const data = await response.arrayBuffer();
                const file = new Blob([data], { type: "model/gltf+binary" });
                array_of_urls.push(URL.createObjectURL(file));
            }
            setarrayOfUrls(array_of_urls);
            console.log('exiting');
        } catch (error) {
            // console.error(error);
            return null;
        }
    };

    useEffect(() => {
        style_numbers.length > 0 && fetchMultiple3dModels(style_numbers);
    }, [style_numbers.length])

    useEffect(() => {
        if (!styleNo) {
            fetchMultiple3dModel(style_numbers[currentIndex - 1]);
            // setConfigIndex(1);
            if(configIndex.current > 0 && configs.length > 0 && configIndex.current <= configs.length) {
                setConfig(configs[configIndex.current - 1]);
            }
        }
    }, [currentIndex, configIndex.current])


    const getProducts = async (collection_name) => {
        setIsGenerating(true);
        setCollectionName(collection_name);
        const selectedCollection = collection_name || 'Vida';
        try {
            const response = await fetch(`${BASE_URL}getAllProducts?collection_name=${selectedCollection}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const products = await response.json();
            let snArray = [];
            for (let product of products) {
                if (!product.isProcessed) {
                    continue;
                }
                snArray.push(product.productName);
            }
            setStyle_numbers(snArray);
            setCurrentIndex(1);
            if(configs.length > 0) {
                setConfig(configs[0]);
            }
        } catch (error) {
            console.error('Error during fetch:', error);
            throw error;
        }
    }

    const captureVideo = (style_number, config_name) =>  {
        console.log("inside captureVideo(): start");
        startRecording(style_number, config_name);
        setTimeout(() => {
            stopRecording()
          }, 8000);

        console.log("inside captureVideo(): stop");
    }

    // For Video recording

    const startRecording = (style_number, config_name) => {
        setIsRotate(true);
        const canvas = canvasRef.current;
        setIsBloom(false);
        chunksRef.current = [];
        // alert("Recording Started!")
        if (canvas.captureStream) {
            const stream = canvas.captureStream();
            mediaRecorderRef.current = new MediaRecorder(stream);

            mediaRecorderRef.current.ondataavailable = (event) => {
                if (event.data && event.data.size > 0) {
                    chunksRef.current.push(event.data);
                }
            };
            mediaRecorderRef.current.onstop = () => {
                const blob = new Blob(chunksRef.current, { type: 'video/webm' });
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                document.body.appendChild(a);
                a.href = url;
                a.download = `${style_number}-${config_name}.webm`;
                a.click();
                URL.revokeObjectURL(url);
                document.body.removeChild(a);
            };
            mediaRecorderRef.current.start();
        }
    };

    const stopRecording = () => {
        // setIsBloom(true);
        setIsRotate(false);
        // alert("Recording Stopped!")
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
        }
    };

    // For Image capture

    const captureCanvas = (mode, style_no, config) => {
        const gl = meshGl.current;
        const scene = meshScene.current;
        const camera = meshCamera.current;
        if (gl && scene && camera) {
            gl.render(scene, camera)
            const dataUrl = gl.domElement.toDataURL('image/png')
            if (mode == 'down') {
                const link = document.createElement('a');
                link.href = dataUrl;
                link.download = `${style_no}-${config['metalColor']}-${config['configName']}.png`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                // console.log("inside Capture canvas");
            }
            if (mode == 'up') {
                uploadImage(dataUrl, style_no, config)
            }
        }
    }

    // For Uploading the Image to SERVER

    const uploadImage = async (dataUrl, style_no, config) => {
        const collection_name = collectionName || 'Vida';
        const originalName = currProdHandle;
        const colors_map = {
            '#ebcf9a': 'YG',
            '#c3c3c3': 'P',
            '#c8c8c8': 'S',
            '#d8d8d8': 'WG',
            '#ebc4af': 'RG'
        };
        try {
            const response = await fetch(`${BASE_URL}uploadImg`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ dataUrl, originalName, collection_name, style_no, config, colors_map, 'user_name' : userData?.preferred_username }),
            });
            if (!response.ok) {
                throw new Error('Failed to upload image');
            }
            const result = await response.json();
            // console.log(result);
            // alert("Image Uploaded!")
            console.log("Image uploaded");
        } catch (error) {
            // console.error('Error uploading image:', error);
        }
    };
    // useEffect(() => {
    //     if (!styleNo && isGenerating) {
    //         const interval = setInterval(() => {
    //             if (configIndex.current < configs.length+1) {
    //                 if (style_numbers.length > 0 && configs.length > 0) {
    //                     console.log('calling Image Capture', style_numbers[currentIndex - 1],configIndex.current < configs.length ? configs[configIndex.current-1] : configs[configs.length-1], configIndex.current, currentIndex);
                        
    //                     captureCanvas('up', style_numbers[currentIndex - 1], configIndex.current < configs.length ? configs[configIndex.current-1] : configs[configs.length-1]);
    //                 }
    //                 if(configIndex.current < configs.length) {
    //                     setConfig(configs[configIndex.current]);
    //                 }
                    
    //                 configIndex.current += 1;
    //                 // console.log('config set in if-1', configs[configIndex]);
    //             }
    //             if (currentIndex < style_numbers.length && configIndex.current >= configs.length + 1) {
    //                 // console.log(configs);
    //                 configIndex.current = 1;
    //                 // if (style_numbers.length > 0 && configs.length > 0) {
    //                 //     console.log('calling Image Capture', style_numbers[currentIndex - 1],configIndex.current < configs.length ? configs[configIndex.current] : configs[configs.length-1], configIndex.current, currentIndex);
                        
    //                 //     captureCanvas('down', style_numbers[currentIndex - 1], configIndex.current < configs.length ? configs[configIndex.current] : configs[configs.length-1]);
    //                 // }
    //                 // console.log("Inside if-2", style_numbers[currentIndex]);
    //                 setShowCanvas(true);
    //                 setCurrentIndex(prevIndex => prevIndex + 1);
    //             }
    //             else if (currentIndex > style_numbers.length) {
    //                 console.log('interval cleared!');
    //                 clearInterval(interval);
    //                 setIsGenerating(false);
    //             }
    //             if (currentIndex >= style_numbers.length && configIndex.current >= configs.length + 1) setIsGenerating(false);
    //         }, 3000);
    //         return () => clearInterval(interval);
    //     }
    // }, [currentIndex, style_numbers, configIndex]);


    // useEffect(() => {
    //     if (!styleNo && isGenerating) {
    //         const interval = setInterval(() => {
    //             if (configIndex.current < configs.length+1) {
    //                 if (style_numbers.length > 0 && configs.length > 0) {
    //                     console.log('calling Image Capture', style_numbers[currentIndex - 1],configIndex.current < configs.length ? configs[configIndex.current-1] : configs[configs.length-1], configIndex.current, currentIndex);
    //                     captureVideo(style_numbers[currentIndex - 1], configIndex.current < configs.length ? configs[configIndex.current-1] : configs[configs.length-1]);
    //                 }
    //                 if(configIndex.current < configs.length) {
    //                     setConfig(configs[configIndex.current]);
    //                 }
    //                 configIndex.current += 1;
    //             }
    //             if (currentIndex < style_numbers.length && configIndex.current >= configs.length + 1) {
    //                 configIndex.current = 1;
    //                 setShowCanvas(true);
    //                 setCurrentIndex(prevIndex => prevIndex + 1);
    //             }
    //             else if (currentIndex > style_numbers.length) {
    //                 console.log('interval cleared!');
    //                 clearInterval(interval);
    //                 setIsGenerating(false);
    //             }
    //             if (currentIndex >= style_numbers.length && configIndex.current >= configs.length + 1) setIsGenerating(false);
    //         }, 12000);
    //         return () => clearInterval(interval);
    //     }
    // }, [currentIndex, style_numbers, configIndex]);


    useEffect(() => {
        const handleBeforeUnload = (event) => {
            // setShowClosePopup(true);
            const confirmationMessage = 'Are you sure you want to leave this page?';
            event.preventDefault();
            event.returnValue = confirmationMessage;
            return confirmationMessage;
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    // const generate = () => {
    //     for (let styleNo of style_numbers) {
    //         console.log("SH StyleNo:", styleNo);
    //         for (let config in configs) {
    //             console.log("SH cofig:", config);
    //         }
    //     }
    // }


    // useEffect(() => {
    //     if(configs.length > 0) {
    //         setConfigIndex(0);
    //         setConfig(configs[0]);
    //         setCurrentIndex(prevIndex => (prevIndex + 1) % style_numbers.length);
    //     }

    // }, [currentIndex])

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         if(configs.length > 0) {
    //             setConfigIndex(prev => (prev + 1) % configs.length);
    //             setConfig(configs[(configIndex+1) % configs.length]);
    //         }

    //     }, 3000)
    //     return () => clearInterval(interval)
    // }, [configIndex, configs])


    

    const logoutUser = () => {
        // auth.removeUser();
        auth.signoutRedirect();
    }

    function handleZRotation(sign) {
        setRotationConstantY(0);
        sign === 'plus' ? setRotationConstantZ(prev => prev + 0.03) : setRotationConstantZ(prev => prev - 0.03);
    }

    function handleYRotation(sign) {
        setRotationConstantZ(0);
        sign === 'plus' ? setRotationConstantY(prev => prev + 0.03) : setRotationConstantY(prev => prev - 0.03);
    }

    function handleCRotation(sign) {
        if(sign === 'plus') {
            if(cPlaneRotation < 0.1) {
                setCPlaneRotation(prev => prev + 0.03)
            }
        }
        else{
            if(cPlaneRotation > -0.1) {
                setCPlaneRotation(prev => prev - 0.03)
            }
        }
    }
    function handleCPosition(sign) {
        console.log('inside c-plane: ', cPlanePosition);
        if(sign === 'plus') {
            if(cPlaneRotation >= -0.5) {
                setCPlanePosition(prev => prev + 0.03)
            }
        }
        else{
            if(cPlaneRotation > -0.1) {
                setCPlanePosition(prev => prev - 0.03)
            }
        }
    }

    const hasRole = (role) => {
        return userRoles.includes(role);
    }

    useEffect(() => {
        setCanCreate(hasRole("SUPER_ADMIN") || hasRole('ADMIN'));
        setCanEdit(hasRole("SUPER_ADMIN") || hasRole('ADMIN'));
        setCanDelete(hasRole("SUPER_ADMIN") || hasRole('ADMIN'));
    }, [userRoles])

    const [currentModelIndex, setCurrentModelIndex] = useState(0);

    const handleRotationComplete = () => {
        if (currentModelIndex < arrayOfUrls.length - 1) {
          setCurrentModelIndex(currentModelIndex + 1);
        } else {
          console.log('All rotations complete');
          // Implement video capture logic here
        }
      };

    useEffect(() => {
        if (!auth.isAuthenticated && !auth.error && !auth.isLoading) {
          auth.signinRedirect();
        }
        if (auth.isAuthenticated) {
            // console.log("SHIVHSHIV auth", auth.user.profile);  
            setUserData(auth.user.profile);
        }
      }, [auth]);


    const [animate, setAnimate] = useState(false);

    const handleButtonClick = () => {
        setAnimate(true);
    };
    
      const handleAnimationEnd = () => {
        setAnimate(false);
    };

    const Scene = ({ animate, onAnimationEnd } ) => {
        const controlsRef = useRef();
        const [angle, setAngle] = useState(0);


        function Jewel(props) {
            console.log('inside jewels');
            const texture = useLoader(RGBELoader, `${process.env.PUBLIC_URL || ''}/blocky_photo_studio_1k.hdr`)
            const [rotation, setRotation] = useState(0);
            console.log('inside jewel');
            useEffect(() => {
                return () => {
                    diamonds.forEach(geometry => geometry.dispose());
                    // Dispose other resources as needed
                };
            }, [diamonds]);
    
            diamonds = [];
            const { scene, nodes: node } = useGLTF(props.style_number)
            // const { scene, nodes: node } = useGLTF(modelUrl)
            console.log(node);
            if (currProdHandle === 'vida-3-dia') {
                baseNode.current = node;
            }
            // console.log("nodes= ", node);
    
            const { gl, scene: threeScene, camera } = useThree();
            meshGl.current = gl;
            meshScene.current = threeScene;
            RingRef.current = threeScene;
            meshCamera.current = camera;
            // const diamondMaterial = new MeshRefractionMaterial();
    
            // diamondMaterial.uniforms.envMap.value = texture;
            // diamondMaterial.uniforms.envMap.value.needsUpdate = true;
            // const isCubeMap = texture.isCubeTexture === true;
            // const w = isCubeMap ? texture.image[0].width : texture.image.width;
            // const cubeSize = 0.25 * w;
            // const _lodMax = Math.floor(Math.log2(cubeSize));
            // const _cubeSize = Math.pow(2, _lodMax);
            // const width = 3 * Math.max(_cubeSize, 16 * 7);
            // const height = 4 * _cubeSize;
            // diamondMaterial.defines = {
            //   ENVMAP_TYPE_CUBEM: isCubeMap,
            //   CUBEUV_MAX_MIP: `${_lodMax}.0`,
            //   CUBEUV_TEXEL_WIDTH: 1 / width,
            //   CUBEUV_TEXEL_HEIGHT: 1 / height,
            //   CHROMATIC_ABERRATIONS: false,
            //   FAST_CHROMA: false
            // };
            // diamondMaterial.needsUpdate = true;
            // const vNormal = normalize((viewMatrixInv * vec4(normalMatrix * transformedNormal.xyz, 0.0)).xyz);
    
            scene.traverse((child) => {
                if (child.isMesh && (!child.name.startsWith("Diamond"))) {
                    child.material.metalness = 1;
                    child.material.roughness = 0.1375;
                    child.material.color.set(ringHex);
                    if(child.isMesh) {
                        child.castShadow = true;
                    }
                    // child.material.visible = false;
                }
                if (
                    child.isMesh
                    && (child.name.startsWith("Diamond"))) {
                    // child.material = diamondMaterial.clone();
                    diamonds.push(child.geometry)
                    if(child.isMesh) {
                        child.castShadow = true;
                    }
                }
            })
    
            // useFrame((state, delta) => {
            //     if (RingRef.current) {
            //         // if(rotation === 0) {
            //         //     startRecording('test', '1');
            //         // }
            //         // if(rotation >= 360) {
            //         //     stopRecording();
            //         // }
            //         if (rotation < 360) {
            //             RingRef.current.rotation.y += delta * Math.PI / 2; // rotate at a rate of 90 degrees per second
            //             setRotation(rotation + (delta * 90));
            //         } else {
            //             props.onRotationComplete();
            //         }
            //     }
            //   });

            useFrame((state, delta) => {
                const newAngle = angle + delta * Math.PI / 6; // Adjust rotation speed as needed
                setAngle(newAngle);
                controlsRef.current.update();
    
                    if (newAngle >= 2 * Math.PI) {
                        setAngle(0);
                        onAnimationEnd();
                        props.onRotationComplete();
                    } else {
                        controlsRef.current.object.position.x = 5 * Math.sin(newAngle);
                        controlsRef.current.object.position.z = 5 * Math.cos(newAngle);
                        controlsRef.current.target.set(0, 0, 0);
                    }
            });
    
            
            useFrame(() => {
                if (RingRef.current) {
                    // isRotate && (RingRef.current.rotation.y += 0.004);
                    isRotate && (RingRef.current.rotation.y += 0.008);
                }
            });
            return <group ref={RingRef} castShadow>
                <primitive object={scene} {...props} castShadow/>
                {diamonds.map((item, index) => (
                    <Diamond {...props} key={index}
                        rotation={[customDiamondRotationX + rotationConstantX, 0 + rotationConstantZ, 0 - rotationConstantY]}
                        scale={0.1001}
                        texture={texture}
                        color={diamondHex}
                        geometry={item} />
                ))}
            </group>
        }

        


        return <>
            {/* dpr={0.9} */}
                    {/* Use this prop to control the resolution of Canvas for better performance */}
                    <ambientLight intensity={0.5} />
                    {/* <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} shadow-mapSize={2048} castShadow /> */}
                    {/* <pointLight position={[10, 10, 10]}/> */}
                    {!isGenerating ? <PresentationControls
                        config={{ mass: 2, tension: 500 }}
                        snap={{ mass: 4, tension: 1500 }}
                        rotation={[0, 0, 0]}
                        polar={[-Math.PI / 3, Math.PI / 3]}
                        azimuth={[-Math.PI / 1.4, Math.PI / 2]}>

                        {modelUrl  && <Jewel
                            rotation={[customRingRotationX + rotationConstantX, 0 + rotationConstantY, 0 + rotationConstantZ]}
                            position={[0, 0, 0 + zoomConstant]}
                            scale={100}
                            style_number={arrayOfUrls[currentModelIndex]}
                            onRotationComplete={handleRotationComplete}
                        />}
                    </PresentationControls> : modelUrl && arrayOfUrls.length ? <Jewel
                        rotation={[customRingRotationX + rotationConstantX, 0 + rotationConstantY, 0 + rotationConstantZ]}
                        position={[0, 0, 0 + zoomConstant]}
                        scale={100}
                        style_number={arrayOfUrls[currentModelIndex]}
                        onRotationComplete={handleRotationComplete}
                    /> : <></>}
                    <OrbitControls ref={controlsRef} enableRotate={false} enablePan={false} />
                    <ContactShadows position={[0, cPlanePosition, 0]} rotation={[0, cPlaneRotation, 0]} opacity={0.75} scale={20} blur={2.5} far={100} />
                    <Environment files={`${process.env.PUBLIC_URL || ''}/unity_env.hdr`} />
                    {isBloom && <EffectComposer>
                        <N8AO aoRadius={0.15} intensity={4} distanceFalloff={2} />
                        <Bloom luminanceThreshold={3.5} intensity={0.5} levels={9} mipmapBlur />
                        <ToneMapping />
                        <Noise opacity={0.01} />
                    </EffectComposer>}
                    {/* <GradientPlane hex={hex} /> */}
        </>
    }

    return (
        <div className='Container'>
            <Alerts />
            <div>
                {/* <div className='info' style={{
                    position: "absolute",
                    top: "5px",
                    left: "5px",
                    zIndex: "2",
                    color: "white",
                    fontSize: "12px"
                }}>
                    <Button size="sm" variant="secondary" onClick={logoutUser}>LOGOUT</Button>
                    <div>
                        ConfigName: {currConfigName}
                    </div>
                    <div>
                        styleNo: {styleNo ? styleNo : style_numbers[currentIndex - 1]}
                    </div>
                </div> */}

                <SaveConfigPopup show={showConfigPopup} setShow={setShowConfigPopup} saveConfig={saveConfig} currConfigId={currConfigId} currConfigName={currConfigName} currView={currView} updateConfig={updateConfig} />
                <ClosePopup show={showClosePopup} setShow={setShowClosePopup} />
                <ConfigListScreen disabled={isGenerating} configs={configs} setConfig={setConfig} deleteConfig={deleteConfig} canDelete={canDelete}/>
                
            </div>
                    <div className='controls'>
                    <div className='wrapper'>
                    <div className='logout'>
                            <Button size="sm" variant="secondary" onClick={logoutUser}>LOGOUT</Button>
                        </div>
                    <div className='detail'>
                        <div className='info-details'>
                            <div className='title'>
                                Config Name: {currConfigName}
                            </div>
                            <div className='title'>
                                Style No: {styleNo ? styleNo : style_numbers[currentIndex - 1]}
                            </div>
                        </div>
                        {/* <div className='logout'>
                            <Button size="sm" variant="secondary" onClick={logoutUser}>LOGOUT</Button>
                        </div> */}
                    </div>
                    <hr className='border'/>
                        {!isGenerating && <div className='color-div'>
                            <span className='title main-title'>Colors</span>
                            <div>
                                <Colors setHex={setHex} title='Background' colors={bgColors} hex={hex} />
                                <span style={{
                                    color: "whitesmoke",
                                    fontSize: "12px"
                                }}>
                                    {hex === '#000000' ? "Transparent" : hex}
                                </span>
                            </div>
                            <div>
                                <Colors setHex={setRingHex} title='Metal' colors={ringColors} hex={ringHex} map={metal_colors_map}/>
                                <span style={{
                                    color: "whitesmoke",
                                    fontSize: "12px"
                                }}>
                                    {metal_colors_map[ringHex]}
                                </span>
                            </div>
                            <div>
                                <Colors setHex={setDiamondHex} title='Diamond' colors={diamondColors} hex={diamondHex} map={diamond_colors_map}/>
                                <span style={{
                                    color: "whitesmoke",
                                    fontSize: "12px"
                                }}>
                                    {diamond_colors_map[diamondHex]}
                                </span>
                            </div>
                        </div>}
                        <hr className='border'/>
                        {!isGenerating && <div className='ring-controls'>
                            <span className='title main-title'>Orientation</span>
                            <div className='master-div'>
                                        <div className='rotation'> 
                                            <div className='rotation-div'>
                                                <span className='rotation-title title'>X-Rotation:</span>
                                                <div className='rotation-controls'>
                                                    <PlusCircle className='rec-btn' onClick={() => (
                                                        setRotationConstantX(prev => prev + -0.03)
                                                    )} />
                                                    <MinusCircle className='rec-btn' onClick={() => (
                                                        setRotationConstantX(prev => prev + 0.03)
                                                    )} />
                                                </div>
                                            </div>
                                            <div className='rotation-div'>
                                                <span className='rotation-title title'>Y-Rotation:</span>
                                                <div className='rotation-controls'>
                                                    <PlusCircle className='rec-btn' onClick={() => (
                                                        handleYRotation('minus')
                                                    )} />
                                                    <MinusCircle className='rec-btn' onClick={() => (
                                                        handleYRotation('plus')
                                                    )} />
                                                </div>
                                            </div>
                                            <div className='rotation-div'>
                                                <span className='rotation-title title'>Z-Rotation:</span>
                                                <div className='rotation-controls'>
                                                    <PlusCircle className='rec-btn' onClick={() => (
                                                        handleZRotation('plus')
                                                    )} />
                                                    <MinusCircle className='rec-btn' onClick={() => (
                                                        handleZRotation('minus')
                                                    )} />
                                                </div>
                                            </div>
                                            <div className='rotation-div'>
                                                <span className='rotation-title title'>C-Rotation:</span>
                                                <div className='rotation-controls'>
                                                    <PlusCircle className='rec-btn' onClick={() => (
                                                        handleCRotation('plus')
                                                    )} />
                                                    <MinusCircle className='rec-btn' onClick={() => (
                                                        handleCRotation('minus')
                                                    )} />
                                                </div>
                                            </div>

                                            <div className='rotation-div'>
                                                <span className='rotation-title title'>C-Position:</span>
                                                <div className='rotation-controls'>
                                                    <PlusCircle className='rec-btn' onClick={() => (
                                                        handleCPosition('plus')
                                                    )} />
                                                    <MinusCircle className='rec-btn' onClick={() => (
                                                        handleCPosition('minus')
                                                    )} />
                                                </div>
                                            </div>
                                            
                                        </div>
                                    {/* <hr className='vertical-border' /> */}
                                        <div className='zoom'>
                                                <Button variant='dark' size='sm' className='' onClick={() => (
                                                            setZoomConstant(prev => prev + 0.3)
                                                        )}>Zoom In</Button>
                                                <Button variant='dark' size='sm' className='' onClick={() => (
                                                    setZoomConstant(prev => prev + -0.3)
                                                        )} >Zoom Out</Button>
                                                <Button variant='dark' size='sm' className='' onClick={() => (
                                                    isRotate ? setIsRotate(false) : setIsRotate(true)
                                                        )}>Spin</Button>
                                        </div>
                            </div>
                            </div>}
                            <hr className='border'/>
                            {!isGenerating && <div className='save-download'>
                                <div className='reset'>
                                    <Sparkles className='rec-btn' onClick={() => isBloom ? setIsBloom(false) : setIsBloom(true)} />
                                </div>
                                <div className='download'>
                                    <Button variant='dark' size='sm' onClick={resetControls}>Reset</Button>
                                    <Button variant='dark' size='sm' onClick={startRecording}>Start</Button>
                                    <Button variant='dark' size='sm' onClick={stopRecording}>Stop</Button>
                                    {/* <ImageDown className='rec-btn rec-img-btn' onClick={() => (captureCanvas('down'))} /> */}
                                    {((!currConfigId && canCreate) || (currConfigId && canEdit)) && <Button variant='dark' size='sm' onClick={() => { setShowConfigPopup(true); }} >Save</Button>}
                                </div>
                            </div>}
                            <hr className='border'/>
                            {!styleNo && <div style={{
                                    color: "white",
                                    fontSize: "12px"
                                }}>
                                <GenerateScreen isGenerating={isGenerating} currentIndex={currentIndex} style_numbers={style_numbers} configIndex={configIndex.current} configs={configs} getProducts={getProducts} />
                            </div>}
                        </div>
                    </div>
                
            
            <Suspense fallback={<Loading />}>
                <Canvas shadows camera={{ position: [0, 0, 12], fov: 25 }} ref={canvasRef} className='canvas' antialias={true}>
                    <Scene animate={animate} onAnimationEnd={handleAnimationEnd}/>
                </Canvas>
                <button onClick={handleButtonClick} style={{ top: '200px', left: '200px' }}>
                    Start 360 Rotation
                </button>
            </Suspense>
            <div className='logo'>
                <img src='/logo-white.png' alt='logo' />
            </div>
        </div>
    )
}

export default RenderScreen_bkp;