import React,{ useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';

import { useAuth } from "react-oidc-context";
import { jwtDecode } from "jwt-decode";
import RenderScreen_bkp from './components/RenderScreen_bkp';
import RenderScreen2 from './components/RenderScreen2';
import RenderScreen3 from './components/RenderScreen3';
import RenderScreen4 from './components/RenderScreen4';

export default function App() {
  const auth = useAuth();
  const [userRoles, setUserRoles] = useState([]);

  useEffect(() => {
    if (!auth.isAuthenticated && !auth.error && !auth.isLoading) {
      auth.signinRedirect();
    }
    if (auth.isAuthenticated) {
      const decodedToken = jwtDecode(auth.user.access_token);
      const roles = decodedToken.realm_access.roles;
      console.log("auth", auth);
      console.log("roles", roles);
      setUserRoles(roles);
    }
  }, [auth]);

  // eslint-disable-next-line default-case
  switch (auth.activeNavigator) {
    case "signinSilent" || "signinRedirect":
      return <div>Signing you in...</div>;
    case "signoutRedirect":
      // return <div>Signing you out...</div>;
      return (
        <div style={{ position: "absolute", inset: "0", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Spinner animation="grow" />
        </div>
      );
  }

  if (auth.isLoading) {
    return (
      <div style={{ position: "absolute", inset: "0", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Spinner animation="grow" />
      </div>
    );
  }

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }


  if (!auth.isAuthenticated && !auth.isLoading) {
    return <>Sign In Failed</>
  }

  return (
    <>
      {
        auth.isAuthenticated ? (
          <Router>
            <Routes>
              {/* <Route exact path="renderapp/:styleNo?" element={<RenderScreen userRoles={userRoles}/>} /> */}
              {/* <Route exact path="renderapp/:styleNo?" element={<RenderScreen_bkp userRoles={userRoles}/>} /> */}
              {/* <Route exact path="renderapp/:styleNo?" element={<RenderScreen3 userRoles={userRoles}/>} /> */}
              <Route exact path="renderapp/:styleNo?" element={<RenderScreen4 userRoles={userRoles}/>} />
            </Routes>
          </Router>
        ) : (
          <div style={{ position: "absolute", inset: "0", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Spinner animation="grow" />
          </div>
        )
      }
    </>
  )
}




