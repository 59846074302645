// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.generateScreen {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* background-color: rgba(255, 255, 255, 0.1); */
    /* background-color: #5e5e5e7a; */
    /* padding: 10px; */
    /* border-radius: 20px; */
}`, "",{"version":3,"sources":["webpack://./src/components/css/GenerateScreen.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,gDAAgD;IAChD,iCAAiC;IACjC,mBAAmB;IACnB,yBAAyB;AAC7B","sourcesContent":[".generateScreen {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    /* background-color: rgba(255, 255, 255, 0.1); */\n    /* background-color: #5e5e5e7a; */\n    /* padding: 10px; */\n    /* border-radius: 20px; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
