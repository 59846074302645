import { Plane, Sphere } from '@react-three/drei';
import React from 'react'
import * as THREE from 'three';

const createGradientTexture = (hex) => {
    const canvas = document.createElement('canvas');
    canvas.width = 256;
    canvas.height = 256;
    const context = canvas.getContext('2d');
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    const gradient = context.createRadialGradient(
        centerX,
        centerY,
        0,
        centerX,
        centerY,
        canvas.width / 2
    );

    gradient.addColorStop(0, hex);
    gradient.addColorStop(0.35, hex);

    context.fillStyle = gradient;
    context.fillRect(0, 0, canvas.width, canvas.height);

    const texture = new THREE.CanvasTexture(canvas);
    return texture;
};

export default function GradientPlane(props) {
    if (props.hex === '#000000') {
        return <>

        </>
    }
    return <Plane args={[75, 75]} {...props} receiveShadow>
        <meshStandardMaterial attach='material' map={createGradientTexture(props.hex)} envMap={null}  reflectivity={0} />
    </Plane>
}

export function GradientSphere(props) {
    if (props.hex === '#000000') {
        return <>

        </>
    }
    return <Sphere args={[25, 25]} rotation={[0, 0, 0]} position={[0, 0, -10]} receiveShadow>
        <meshStandardMaterial attach='material' map={createGradientTexture(props.hex)} side={THREE.DoubleSide}/>
    </Sphere>
}

export function BottomPlane(props) {
    if (props.hex === '#000000') {
        return <>

        </>
    }

    return <Plane args={[75, 75]} {...props} receiveShadow>
        <meshStandardMaterial attach='material'  map={createGradientTexture(props.hex)}/>
    </Plane>
}
