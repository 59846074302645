import React, { useState, useEffect, useRef } from 'react';
import { Form, FormControl, ListGroup } from 'react-bootstrap';
import './css/SearchableSelect.css';
import crossSvg from '../assets/cross.svg';

const SearchableSelect = ({ disabled = false, options, selectedOption, onSelect }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState(null);
    const [filteredOptions, setFilteredOptions] = useState(options);

    const selectContainerRef = useRef(null);
    const inputRef = useRef(null);

    const handleSearch = (e) => {
        const term = e.target.value;
        setSearchTerm(term);
        const filtered = options.filter(option =>
            option.toLowerCase().includes(term.toLowerCase())
        );
        setFilteredOptions(filtered);
        setIsOpen(true);
    };

    const handleSelect = (option) => {
        setSearchTerm(null);
        onSelect(option);
        setIsOpen(false);
    };

    const removeSelection = () => {
        setSearchTerm(null);
        onSelect('');
        setIsOpen(false);
    };

    useEffect(() => {
        setFilteredOptions(options);
    }, [options]);

    const handleInputBlur = () => {
        setTimeout(() => {
            if (!selectContainerRef.current?.contains(document.activeElement)) {
                setIsOpen(false);
            }
        }, 100);
    };

    return (
        <div className="select-container" ref={selectContainerRef}>
            <Form.Control
                size="sm"
                type="text"
                placeholder="Search or Select"
                value={searchTerm == null ? selectedOption : searchTerm}
                onChange={handleSearch}
                onFocus={() => { setIsOpen(true); }}
                onBlur={handleInputBlur}
                style={{ width: "100%" }}
                disabled={disabled}
                ref={inputRef}
            />
            <div className='crossIcon' onClick={!disabled ? removeSelection : null}>
                <img src={crossSvg} alt="cross" />
            </div>
            {
                isOpen && (
                    <div className='options-list'>
                        <ListGroup variant="flush" size="sm">
                            {/* <ListGroup.Item
                                    key={''}
                                    variant="light"
                                    action
                                    onClick={() => handleSelect('')}
                                >
                                    null
                                </ListGroup.Item> */}
                            {filteredOptions.map(option => (
                                <ListGroup.Item
                                    key={option}
                                    variant="dark"
                                    action
                                    onClick={() => handleSelect(option)}
                                >
                                    {option}
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </div>
                )
            }
        </div>
    );
};

export default SearchableSelect;
