import React, { useState, useEffect } from 'react';
import { Table, Modal, Button, Form } from 'react-bootstrap';

const SaveConfigPopup = ({ show, setShow, saveConfig, currConfigId, currConfigName, currView, updateConfig }) => {
    const [configName, setConfigName] = useState('');
    const [selectedOptionGroup1, setSelectedOptionGroup1] = useState('');
    const [selectedOptionGroup2, setSelectedOptionGroup2] = useState('');

    const [toggleAlert, setToggleAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const [alertColor, setAlertColor] = useState('green');

    const alertStyle = {
        position: "absolute",
        top: "0",
        right: "0",
        left: "0",
        zIndex: "10",
        backgroundColor: alertColor
    }

    function Alerts() {
        return (
            <>
                {toggleAlert && <div className="alert" style={alertStyle}>{alertMessage}</div>}
            </>
        )
    }

    function showAlert(message, color, time = 3000) {
        setAlertMessage(message);
        setAlertColor(color);
        setToggleAlert(true);
        setTimeout(function () { setToggleAlert(false) }, time)
    }

    const handleClose = () => {
        // setConfigName('');
        setSelectedOptionGroup1(currConfigId ? currView : '');
        // setSelectedOptionGroup2('');
        setShow(false)
    };

    const handleConfigNameChange = (e) => {
        setConfigName(e.target.value);
    }

    const handleGroup1Change = (event) => {
        const selectedValue = event.target.value;
        setSelectedOptionGroup1(prevValue => prevValue === selectedValue ? '' : selectedValue);
    };

    const handleGroup2Change = (event) => {
        const selectedValue = event.target.value;
        setSelectedOptionGroup2(prevValue => prevValue === selectedValue ? '' : selectedValue);

    };

    const handleSaveConfig = () => {
        if (!configName || configName == '') {
            showAlert("Enter Config Name !", "lightcoral");
            return;
        }
        if (!selectedOptionGroup1 || selectedOptionGroup1 == '') {
            showAlert("Choose Any View (Front, Back, Left, Right, Top, Bottom)!", "lightcoral");
            return;
        }
        const view = selectedOptionGroup1;
        if(currConfigId && currConfigName == configName)
        {
            updateConfig(currConfigId, configName, view);
        }
        else{
            saveConfig(configName, view);
        }
        // setConfigName('');
        // setSelectedOptionGroup1('');
    }

    useEffect(() => {
        setConfigName(currConfigId ? currConfigName : '');
        setSelectedOptionGroup1(currConfigId ? currView : '');
    }, [currConfigName]);

    return (
        <div>
            <Alerts />
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton style={{ fontFamily: "Nunito" }}>
                    <Modal.Title>Save Configuration</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table style={{ width: "100%", fontFamily: "Nunito", borderColor: "white" }}>
                        <tbody>
                            <tr>
                                <td style={{ width: "20%" }}>ConfigName</td>
                                <td >:</td>
                                <td style={{ width: "95%", float: "right" }}>
                                    <Form.Control
                                        size="sm"
                                        type="text"
                                        placeholder={`Enter Config Name`}
                                        value={configName}
                                        onChange={handleConfigNameChange}
                                        // disabled={currConfigId}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: "20%" }}>ChooseView</td>
                                <td >:</td>
                                <td style={{ width: "95%", float: "right" }}>
                                    <div key={`inline-radio`}>
                                        <Form.Check
                                            inline
                                            label="Front"
                                            name="group1"
                                            type="radio"
                                            id={`inline-radio-1`}
                                            value="F"
                                            checked={selectedOptionGroup1 === 'F'}
                                            onChange={handleGroup1Change}
                                        />
                                        <Form.Check
                                            inline
                                            label="Back"
                                            name="group1"
                                            type="radio"
                                            id={`inline-radio-2`}
                                            value="BK"
                                            checked={selectedOptionGroup1 === 'BK'}
                                            onChange={handleGroup1Change}
                                        />
                                        <Form.Check
                                            inline
                                            label="Left"
                                            name="group1"
                                            type="radio"
                                            id={`inline-radio-3`}
                                            value="L"
                                            checked={selectedOptionGroup1 === 'L'}
                                            onChange={handleGroup1Change}
                                        />
                                        <Form.Check
                                            inline
                                            label="Right"
                                            name="group1"
                                            type="radio"
                                            id={`inline-radio-4`}
                                            value="R"
                                            checked={selectedOptionGroup1 === 'R'}
                                            onChange={handleGroup1Change}
                                        />
                                        <Form.Check
                                            inline
                                            label="Top"
                                            name="group1"
                                            type="radio"
                                            id={`inline-radio-5`}
                                            value="T"
                                            checked={selectedOptionGroup1 === 'T'}
                                            onChange={handleGroup1Change}
                                        />
                                        <Form.Check
                                            inline
                                            label="Bottom"
                                            name="group1"
                                            type="radio"
                                            id={`inline-radio-6`}
                                            value="B"
                                            checked={selectedOptionGroup1 === 'B'}
                                            onChange={handleGroup1Change}
                                        />
                                    </div>
                                    {/* <div key={`inline-radio`}>
                                        <Form.Check
                                            inline
                                            label="Top"
                                            name="group2"
                                            type="radio"
                                            id={`inline-radio-1`}
                                            value="T"
                                            checked={selectedOptionGroup2 === 'T'}
                                            onChange={handleGroup2Change}
                                        />
                                        <Form.Check
                                            inline
                                            label="Bottom"
                                            name="group2"
                                            type="radio"
                                            id={`inline-radio-2`}
                                            value="B"
                                            checked={selectedOptionGroup2 === 'B'}
                                            onChange={handleGroup2Change}
                                        />
                                    </div> */}
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer style={{ justifyContent: "center" }}>
                    <Button style={{ fontFamily: "Nunito" }} variant="secondary" onClick={handleSaveConfig}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default SaveConfigPopup;