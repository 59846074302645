import React, { useState } from 'react';
import axios from 'axios';
import { BASE_URL } from '../lib/commons';
import '../components/css/UploadHDRFile.css';

const UploadHDRFile = ({ fetchHDRFiles, showAlert }) => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [message, setMessage] = useState('');

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      setMessage('Please select a file first.');
      return;
    }

    setUploading(true);
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post(`${BASE_URL}uploadHDRFile`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.data.success) {
        setMessage('File uploaded successfully!');
        showAlert('File uploaded successfully!', "lightgreen");
      } else {
        setMessage('Failed to upload the file.');
        showAlert('Failed to upload the file.', "lightcoral");
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      setMessage('Error uploading file.');
      showAlert(message, "lightcoral");
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="upload-container">
      <label className="file-input-label">
        Choose File
        <input type="file" onChange={handleFileChange} accept=".hdr" className="file-input" />
      </label>
      <button onClick={handleUpload} disabled={uploading} className="upload-button">
        {uploading ? 'Uploading...' : 'Upload HDR File'}
      </button>
      {/* {message && <p className="message">{message}</p>} */}
    </div>
  );
};

export default UploadHDRFile;
