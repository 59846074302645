import React, { useState, useEffect } from 'react';
import { Table, Modal, Button, Form } from 'react-bootstrap';

const ClosePopup = ({ show, setShow }) => {

    const handleClose = () => {
        setShow(false)
    };

    const handleCancle = () => {
        setShow(false)
    };

    const handleContinue = () => {
        setShow(false)
    };


    return (
        <div>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton style={{ fontFamily: "Nunito" }}>
                    <Modal.Title>Confirm Refresh/Close</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ fontFamily: "Nunito" }}>
                        Image generation process is running. Closing or Refreshing the tab will result in killing the process for further Products. Do you still want to continue?
                    </div>
                </Modal.Body>
                <Modal.Footer style={{ justifyContent: "center" }}>
                    <Button style={{ fontFamily: "Nunito" }} variant="secondary" onClick={handleCancle}>
                        Cancel
                    </Button>
                    <Button style={{ fontFamily: "Nunito" }} variant="danger" onClick={handleContinue}>
                        Continue
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ClosePopup;