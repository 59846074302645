// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upload-container {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    margin: 20px;
    gap: 10px;
  }
  
  .file-input-label {
    display: inline-block;
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .file-input {
    display: none;
  }
  
  .upload-button {
    padding: 5px 10px;
    background-color: #28a745;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .upload-button:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
  }
  
  .message {
    font-size: 14px;
    color: #333;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/css/UploadHDRFile.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,4BAA4B;IAC5B,mBAAmB;IACnB,YAAY;IACZ,SAAS;EACX;;EAEA;IACE,qBAAqB;IACrB,iBAAiB;IACjB,yBAAyB;IACzB,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,iBAAiB;IACjB,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,yBAAyB;IACzB,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,WAAW;EACb","sourcesContent":[".upload-container {\n    display: flex;\n    /* flex-direction: column; */\n    align-items: center;\n    margin: 20px;\n    gap: 10px;\n  }\n  \n  .file-input-label {\n    display: inline-block;\n    padding: 5px 10px;\n    background-color: #007bff;\n    color: white;\n    cursor: pointer;\n    border-radius: 5px;\n    margin-bottom: 10px;\n  }\n  \n  .file-input {\n    display: none;\n  }\n  \n  .upload-button {\n    padding: 5px 10px;\n    background-color: #28a745;\n    color: white;\n    border: none;\n    cursor: pointer;\n    border-radius: 5px;\n    margin-bottom: 10px;\n  }\n  \n  .upload-button:disabled {\n    background-color: #6c757d;\n    cursor: not-allowed;\n  }\n  \n  .message {\n    font-size: 14px;\n    color: #333;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
