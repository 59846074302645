import React, { useEffect, useRef } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { BufferGeometry, DoubleSide, Float32BufferAttribute } from 'three';

const CurvedPlane = (props) => {
    const meshRef = useRef();
  
    useEffect(() => {
      const geometry = new BufferGeometry();
      
      const width = 100;
      const height = 100;
      const segments = 500;
      const vertices = [];
      const indices = [];
  
      for (let i = 0; i <= segments; i++) {
        for (let j = 0; j <= segments; j++) {
          const x = (i / segments) * width - width / 4;
          const y = (j / segments) * height - height / 4;
          const z = 50 * Math.pow((x / width) * 4, 4); // Adjust this formula to control the curvature
          vertices.push(x, y, z);
        }
      }
  
      for (let i = 0; i < segments; i++) {
        for (let j = 0; j < segments; j++) {
          const a = i * (segments + 1) + j;
          const b = i * (segments + 1) + (j + 1);
          const c = (i + 1) * (segments + 1) + j;
          const d = (i + 1) * (segments + 1) + (j + 1);
          indices.push(a, b, c);
          indices.push(b, d, c);
        }
      }
  
      geometry.setAttribute('position', new Float32BufferAttribute(vertices, 3));
      geometry.setIndex(indices);
      geometry.computeVertexNormals();
  
      meshRef.current.geometry = geometry;
    }, []);

    if (props.hex === '#000000') {
      return <>

      </>
    }
  
    return (
        <mesh receiveShadow {...props} ref={meshRef} >
          <meshPhysicalMaterial color={props.hex} receiveShadow side={DoubleSide} />
        </mesh>
    );
  };
export default CurvedPlane;