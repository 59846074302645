import React, { useState } from 'react';
import '../components/css/DropdownComponent.css';
import {
  Trash
} from 'lucide-react';
import DeleteEnvPopUp from './DeleteEnvPopUp';

const DropdownComponent = ({ handleFetchHDR, items, selectedItem, onChange, onDelete, showAlert, fetchHdrFiles }) => {
  const [valueItem, setvalueItem] = useState();
  const [openPopUp , setopenPopUp ] = useState(false);
  const handleChange = (event) => {
    onChange(`/hdr-files/${event.target.value}`);
    const selectedValue = event.target.value.replace('/hdr-files/', '');
    handleFetchHDR(selectedValue);
    setvalueItem(selectedValue);
  };

  const selectedValue = selectedItem.replace('/hdr-files/', '');
  const handleDelete = () => {
    onDelete(valueItem);
    setopenPopUp(false);
    showAlert(`${valueItem} Deleted!`, 'lightgreen')
    fetchHdrFiles();
  }
  const closePopUp = () => {
    setopenPopUp(false);
  }

  return (
    <div className="dropdown-container">
      {openPopUp && <DeleteEnvPopUp handleDelete={handleDelete} closePopUp={closePopUp} selectedItem={valueItem}/>}
      <label htmlFor="dropdown" className="dropdown-label">Choose an ENV: </label>
      <select id="dropdown" value={selectedValue} onChange={handleChange} className="dropdown-select">
        {/* <option value="">{items[items.length - 1]}</option> */}
        {items.map((item, index) => (
          <option key={index} value={item}>
            {item}
          </option>
        ))}
      </select>
      <span className='delete' onClick={() => setopenPopUp(!openPopUp)}>
        <Trash />
      </span>
    </div>
  );
};

export default DropdownComponent;
