// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-container {
  position: relative;
  /* width: 95%; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.select-container .options-list {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid rgba(0,0,0,.125);
  background-color: #fff;
}

.select-container .list-group-item {
  position: relative;
  display: block;
  padding: 2px 10px;
}

.select-container .crossIcon {
  width: 10px;
  height: 10px;
  /* border: 2px solid red; */
  margin-left: 5px;
}

.select-container .crossIcon img{
  width: 100%;
  height: 100%;
  display: flex;
  cursor: pointer;
  transition: all 0.2s;
  filter: invert(1);
}

.select-container .crossIcon img:hover{
  transform:rotate(90deg);
}
`, "",{"version":3,"sources":["webpack://./src/components/css/SearchableSelect.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,aAAa;EACb,WAAW;EACX,iBAAiB;EACjB,gBAAgB;EAChB,kCAAkC;EAClC,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,2BAA2B;EAC3B,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,eAAe;EACf,oBAAoB;EACpB,iBAAiB;AACnB;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":[".select-container {\n  position: relative;\n  /* width: 95%; */\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.select-container .options-list {\n  position: absolute;\n  top: 100%;\n  left: 0;\n  z-index: 1000;\n  width: 100%;\n  max-height: 200px;\n  overflow-y: auto;\n  border: 1px solid rgba(0,0,0,.125);\n  background-color: #fff;\n}\n\n.select-container .list-group-item {\n  position: relative;\n  display: block;\n  padding: 2px 10px;\n}\n\n.select-container .crossIcon {\n  width: 10px;\n  height: 10px;\n  /* border: 2px solid red; */\n  margin-left: 5px;\n}\n\n.select-container .crossIcon img{\n  width: 100%;\n  height: 100%;\n  display: flex;\n  cursor: pointer;\n  transition: all 0.2s;\n  filter: invert(1);\n}\n\n.select-container .crossIcon img:hover{\n  transform:rotate(90deg);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
