import { CubeCamera, MeshRefractionMaterial } from '@react-three/drei'
import React from 'react'
import * as THREE from 'three';

export default function Diamond(props) {
    return <CubeCamera resolution={256} frames={1} envMap={props.texture}>
    {(texture) => (
     
        <mesh  {...props} castShadow>
          <MeshRefractionMaterial
              color={props.color}
              envMap={props.texture}
              toneMapped={false}
              side={THREE.DoubleSide}
              // bounces={1}
          />
  </mesh>
    )}
  </CubeCamera>
}
