// import Circle from '@uiw/react-color-circle'

import Circle from "@uiw/react-color-circle";
import { Plus } from "lucide-react";
import { useState } from "react";
import { HexAlphaColorPicker, HexColorInput, HexColorPicker } from "react-colorful";

// const ringColors = ['#ebcf9a', '#c3c3c3', '#c8c8c8', '#d8d8d8', '#ebc4af'];
const ringColors = ['#ebcf9a', '#c3c3c3', '#c8c8c8', '#d8d8d8', '#e3a98a'];
// const diamondColors = ['#E0115F', '#4cb3a0', '#FFFFFF'];
const diamondColors = ['#E0115F', '#0d613c', '#FFFFFF'];
// #094f2d


function PopUp(props) {
    return <div className="extra-color">
        {props.colors.map((color, index) => (
                <div className={props.hex === color? `key-circle active` : `key-circle`} key={index}
                    onClick={() => {props.setHex(color)}} 
                style={{
                    backgroundColor: color,
                    color: "white",
                    width: '30px', 
                    height: '30px',
                    borderRadius: '50%'
                }}></div>
            ))}
    </div>
}

export default function Colors(props) {
    const [colorPop, setColorPop] = useState(false)


    return  <div className='colors'>
        <span className='color-title title'>
            {props.title}:
        </span>
        <div className="main-circle">
            {props.colors.map((color, index) => (
                <div title={props.title === 'Background' ? color : props.map[color]} className={props.hex === color && color !== '#000000' ? `key-circle active` : `key-circle`} key={index}
                    onClick={() => {props.setHex(color)}} 
                style={{
                    backgroundColor: color,
                    color: "white",
                    width: '30px', 
                    height: '30px',
                    borderRadius: '50%'
                }}>{color === '#000000' && <span style={{ 
                    top: "50%",
                    bottom: "50%",
                    left: "50%",
                    right: "50%",
                    fontSize: "12px",
                    padding: "4px"
                     }}>png</span>}</div>
            ))}
            <div className="key-circle"> 
                <Plus className="rec-btn" onClick={() => setColorPop(true)}/>
            </div>
            {props.title === 'Background' && colorPop && 
                <div className="color-picker">
                    <Plus className="rec-btn close-btn" onClick={() => setColorPop(false)}/>
                    <HexAlphaColorPicker color={props.hex} onChange={props.setHex}/>
                    <HexColorInput className="color-input" color={props.hex} onChange={props.setHex} />
                </div>}
            {props.title ==='Metal' && colorPop && 
            <div className="color-picker">
                    <Plus className="rec-btn close-btn" onClick={() => setColorPop(false)}/>
                    <HexAlphaColorPicker color={props.hex} onChange={props.setHex}/>
                    <HexColorInput className="color-input" color={props.hex} onChange={props.setHex} />
                    <PopUp hex={props.hex} colors={ringColors} setHex={props.setHex}/>
                </div>
             }
             {props.title ==='Diamond' && colorPop && 
            <div className="color-picker">
                    <Plus className="rec-btn close-btn" onClick={() => setColorPop(false)}/>
                    <HexAlphaColorPicker color={props.hex} onChange={props.setHex}/>
                    <HexColorInput className="color-input" color={props.hex} onChange={props.setHex} />
                    <PopUp hex={props.hex} colors={diamondColors} setHex={props.setHex}/>
                </div>
             }
    </div>
</div>
}