import React from 'react'

export default function DeleteEnvPopUp(props) {
  return (
    <div className='close-popup'>
        <span>Are you sure you want to delete {props.selectedItem}</span>
        <div className='btns'>
            <button className='y-n red' onClick={props.handleDelete}>Yes</button>
            <button className='y-n green' onClick={props.closePopUp}>No</button>
        </div>
    </div>
  )
}
