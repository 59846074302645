import { PositionPoint } from '@react-three/drei';
import { useState } from 'react';
import { Table, Button } from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { BASE_URL } from '../lib/commons'
import { useEffect } from 'react';

const ConfigListScreen = ({ disabled = false, configs, setConfig, deleteConfig, canDelete }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [toggleAlert, setToggleAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const [alertColor, setAlertColor] = useState('green');

    const alertStyle = {
        position: "absolute",
        top: "0",
        right: "0",
        left: "0",
        zIndex: "10",
        backgroundColor: alertColor
    }

    function Alerts() {
        return (
            <>
                {toggleAlert && <div className="alert" style={alertStyle}>{alertMessage}</div>}
            </>
        )
    }

    function showAlert(message, color, time = 3000) {
        setAlertMessage(message);
        setAlertColor(color);
        setToggleAlert(true);
        setTimeout(function () { setToggleAlert(false) }, time)
    }

    const editConfig = (index) => {
        setConfig(configs[index]);
        setShow(false);
    }

    return (
        <>
            <Alerts />
            <div style={{
                position: "absolute",
                top: "5px",
                right: "5px",
                zIndex: "2",
            }}>
                <Button disabled={disabled} size="sm" variant="dark" onClick={handleShow}>
                    See Configs
                </Button>

                <Offcanvas show={show} onHide={handleClose} placement={"end"}>
                    {/* <Offcanvas style={{backgroundColor:"rgba(255, 255, 255, 0.1)"}} show={show} onHide={handleClose} placement={"end"}> */}
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Configuration List</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        {
                            configs.length > 0 ? (
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Config Name</th>
                                            <th>Load</th>
                                            {canDelete && <th>Delete</th>}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {configs.map((config, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{config.configName}</td>
                                                <td>
                                                    <Button disabled={disabled} size="sm" variant="dark" onClick={() => editConfig(index)}>
                                                        LOAD
                                                    </Button>
                                                </td>
                                                {canDelete && <td>
                                                    <Button disabled={disabled} size="sm" variant="danger" onClick={() => deleteConfig(config.configName)}>
                                                        DELETE
                                                    </Button>
                                                </td>}
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            ) :
                                (
                                    <span>No Configuration Yet!</span>
                                )
                        }
                    </Offcanvas.Body>
                </Offcanvas>
            </div>
        </>
    )
}

export default ConfigListScreen;