import { memo, Suspense, useCallback, useRef, useState } from 'react'
import * as THREE from 'three';
import { Canvas, useFrame, useLoader, useThree } from '@react-three/fiber'
import {
    OrbitControls, Environment, useGLTF, PresentationControls,
    ContactShadows,
    PivotControls,
    AccumulativeShadows,
    RandomizedLight,
    Center,
} from '@react-three/drei'
import axios from 'axios';
import {
    PlusCircle, MinusCircle, Sparkles,
    ImageDown,
    ArrowBigLeft,
    ArrowLeft,
    ChevronLeft,
    ChevronRight,
    Download,
    LucideTv,
} from 'lucide-react';

import { GLTFLoader, RGBELoader } from 'three-stdlib'
import Loader from "react-js-loader";
import Colors from './Colors.jsx';
import { useEffect } from 'react';
import {
    EffectComposer, Bloom, N8AO,
    ToneMapping, Noise
} from '@react-three/postprocessing'
import SaveConfigPopup from './SaveConfigPopup.jsx'
import { BASE_URL } from '../lib/commons.js'
import ConfigListScreen from './ConfigListScreen.jsx';
import { useParams } from 'react-router-dom';
import GenerateScreen from './GenerateScreen.jsx';
import ClosePopup from './ClosePopup.jsx';

import { Button } from 'react-bootstrap';
import { useAuth } from "react-oidc-context";
import GradientPlane, { BottomPlane, GradientSphere } from './GradientPlane.jsx';
import Diamond from './Diamond.jsx';
import { ACESFilmicToneMapping, WebGLCubeRenderTarget } from 'three';
import DropdownComponent from './DropDownMenu.jsx';
import CurvedPlane from './CurvedPlane.jsx';
import UploadHDRFile from './UploadHDRFile.jsx';
import PercentageLoader from './PercentageLoader.jsx';

// Constants and variables
let diamonds = [];
const bgColors = ['#FFFFFF', '#28282B', '#000000'];
// const ringColors = ['#ebcf9a', '#c3c3c3', '#c8c8c8'];
const ringColors = ['#e8bc6b', '#c3c3c3', '#c8c8c8'];
// #ebbd67

const diamondColors = ['#E0115F', '#4cb3a0', '#FFFFFF'];
const hdr_env_default = '/brown_photostudio_02_1k.hdr';
const hdr_env_test = '/unity-hdr/MatrixLightTent1.hdr';
const hdr_env_diamond = '/diahdr4.hdr';
let metal_colors_map = {
    '#ebcf9a': 'Yellow Gold',
    '#c3c3c3': 'Platinum',
    '#c8c8c8': 'Silver',
    '#d8d8d8': 'White Gold',
    '#ebc4af': 'Rose Gold'
};

let diamond_colors_map = {
    '#E0115F': 'Ruby',
    '#4cb3a0': 'Green',
    '#FFFFFF': 'Natural',
};


// Main funtion

const RenderScreen4 = memo(({userRoles}) => {
    const auth = useAuth();
    const [userData, setUserData] = useState(null);

    const [canCreate, setCanCreate] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [canDelete, setCanDelete] = useState(false);

    const { styleNo } = useParams();
    const [category, setCategory] = useState();
    // State and Ref variables
    const [showConfigPopup, setShowConfigPopup] = useState(false);
    const [showClosePopup, setShowClosePopup] = useState(false);

    const [isGenerating, setIsGenerating] = useState(false);

    const [hex, setHex] = useState('#FFFFFF');
    const [ringHex, setRingHex] = useState('#e8bc6b');
    const [diamondHex, setDiamondHex] = useState('#FFFFFF');

    const meshGl = useRef(null);
    const meshCamera = useRef(null);
    const meshScene = useRef(null);
    const canvasRef = useRef();
    const mediaRecorderRef = useRef();
    const chunksRef = useRef([]);

    const [modelUrl, setModelUrl] = useState(null);
    const [currProdHandle, setcurrProdHandle] = useState('vida-3-dia');

    const baseNode = useRef();
    const [isBloom, setIsBloom] = useState(false);
    const [nextModelUrl, setNextModelUrl] = useState(null);
    const [customJewelRotationX, setCustomJewelRotationX] = useState(-0.3);
    // const [customJewelRotationX, setCustomJewelRotationX] = useState(0);
    const [customDiamondRotationX, setcustomDiamondRotationX] = useState(1.2707);
    // const [customDiamondRotationX, setcustomDiamondRotationX] = useState(-0.3);
    // const [customDiamondRotationX, setcustomDiamondRotationX] = useState(1.3207);
    const [customJewelPositionY, setCustomJewelPositionY] = useState(0.15);
    const [rotationConstantX, setRotationConstantX] = useState(0);
    const [rotationConstantY, setRotationConstantY] = useState(0);
    const [rotationConstantZ, setRotationConstantZ] = useState(0);
    // const [cPlaneRotationX, setCPlaneRotationX] = useState(-0.20);
    const [cPlaneRotationX, setCPlaneRotationX] = useState(3);
    const [cPlaneRotationY, setCPlaneRotationY] = useState(1.59);
    const [cPlaneRotationZ, setCPlaneRotationZ] = useState(0);
    // const [cPlanePosition, setCPlanePosition] = useState(15.3);
    const [cPlanePosition, setCPlanePosition] = useState(12);
    const [zoomConstant, setZoomConstant] = useState(0);
    const [isRotate, setIsRotate] = useState(false);
    const [isRotateNeg, setIsRotateNeg] = useState(false);
    const RingRef = useRef(null);
    const [configs, setConfigs] = useState([]);
    const [currConfigId, setCurrConfigId] = useState(null);
    const [currConfigName, setCurrConfigName] = useState('Default');
    const [currView, setCurrView] = useState(null);

    const [toggleAlert, setToggleAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const [alertColor, setAlertColor] = useState('green');
    const [currentIndex, setCurrentIndex] = useState(0);
    const [showCanvas, setShowCanvas] = useState(false);
    // const [configIndex, setConfigIndex] = useState(0);
    const configIndex = useRef(1);
    const [collectionName, setCollectionName] = useState(null);
    const [style_numbers, setStyle_numbers] = useState([]);
    const prevRotation = useRef(0);
    const firstTime = useRef(true);
    const [imageGenerate, setImageGenerate] = useState(false);
    const [videoGenerate, setVideoGenerate] = useState(false);
    const [loading, setLoading] = useState(true);
    const [pRotation, setPRotation] = useState([0, 0, 0]);
    const presentationRef = useRef(null);
    const [hdrFiles, setHdrFiles] = useState([]);
    const [selectedItem, setSelectedItem] = useState('');
    const [currHDRFile, setCurrHDRFile] = useState();
    const [load4K, setLoad4K] = useState(false);

    const NEW_BASE_URL = process.env.REACT_APP_BASE_URL_FILE_SERVER || 'https://jeweladmin.stylebyvida.com/';

    const alertStyle = {
        position: "absolute",
        top: "0",
        right: "0",
        left: "0",
        zIndex: "100000",
        backgroundColor: alertColor
    }

    function Alerts() {
        return (
            <>
                {toggleAlert && <div className="alert" style={alertStyle}>{alertMessage}</div>}
            </>
        )
    }

    function showAlert(message, color, time = 3000) {
        setAlertMessage(message);
        setAlertColor(color);
        setToggleAlert(true);
        setTimeout(function () { setToggleAlert(false) }, time)
    }


    // API call to get constants------------------------------------------------------------------------ 
    useEffect(() => {
        getConfigs();
        fetchHdrFiles();
    }, [])
    

    const fetchHdrFiles = async () => {
        try {
          const response = await axios.get(`${BASE_URL}listHDRFiles`);
          if (response.data.success) {
            setHdrFiles(response.data.files);
            // console.log("hdr-files----------",hdrFiles);
            setSelectedItem(response.data.files[0] ? `hdr-files/env-metal-1.hdr` : '');
            // const selectedValue = response.data.files[0].replace('/hdr-files/', '');
            const selectedValue = 'env-metal-1.hdr'
            fetchHDRFile(selectedValue) // set first file as default
            // console.log("hdr-files----------",selectedItem);
          } else {
            console.error('Failed to fetch HDR files');
          }
        } catch (error) {
          console.error('Failed to fetch HDR files', error);
        }
      };

      //To Delete HDR Files

      const handleDelete = async (item) => {
        const itemName = item.replace('/hdr-files/', '');
        try {
          const response = await fetch(`${BASE_URL}hdr-files/${itemName}`, {
            method: 'DELETE',
          });
    
          if (response.ok) {
            // Update the items state after successful deletion
            // setItems((prevItems) => prevItems.filter((i) => i !== item));
            // if (selectedItem === item) {
            //   setSelectedItem(''); // Reset selected item if the deleted item was selected
            // }
          } else {
            console.error('Failed to delete the file');
          }
        } catch (error) {
          console.error('Error:', error);
        }
      };

    const saveConfig = async (configName, view) => {
        try {
            const response = await fetch(`${BASE_URL}addConfig`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ configName, backgroundColor: hex, metalColor: ringHex, diamondColor: diamondHex, zoomConstant, rotationConstantX, rotationConstantY, rotationConstantZ, view, 'user_name' : userData?.preferred_username }),
            });
            if (!response.ok) {
                const { success, message } = await response.json();
                showAlert(message, success == "true" ? "lightgreen" : "lightcoral");
            }
            const { success, message } = await response.json();
            showAlert(message, success == "true" ? "lightgreen" : "lightcoral");
            getConfigs();
        } catch (error) {
            showAlert("Something wrong happen while saving the configuration! Try again after some time.", "lightcoral");
        }
        setShowConfigPopup(false);
    }

    const updateConfig = async (configId, configName, view) => {
        try {
            const response = await fetch(`${BASE_URL}editConfig`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ configId, configName, backgroundColor: hex, metalColor: ringHex, diamondColor: diamondHex, zoomConstant, rotationConstantX, rotationConstantY, view, 'user_name' : userData?.preferred_username }),
            });
            if (!response.ok) {
                const { success, message } = await response.json();
                showAlert(message, success == "true" ? "lightgreen" : "lightcoral");
            }
            const { success, message } = await response.json();
            showAlert(message, success == "true" ? "lightgreen" : "lightcoral");
            getConfigs();
        } catch (error) {
            showAlert("Something wrong happen while saving the configuration! Try again after some time.", "lightcoral");
        }
        setShowConfigPopup(false);
    }

    const deleteConfig = async (configName) => {
        try {
            const response = await fetch(`${BASE_URL}deleteConfig`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ configName, 'user_name' : userData?.preferred_username }),
            });
            if (!response.ok) {
                const { success, message } = await response.json();
                showAlert(message, success == "true" ? "lightgreen" : "lightcoral");
            }
            const { success, message } = await response.json();
            showAlert(message, success == "true" ? "lightgreen" : "lightcoral");
            getConfigs();
        } catch (error) {
            showAlert("Something wrong happen while deleting the configuration! Try again after some time.", "lightcoral");
        }
    }

    const setConfig = (config) => {
        setCurrConfigId(config.id);
        setCurrConfigName(config.configName);
        setCurrView(config.view);
        setZoomConstant(config.zoomConstant);
        setRotationConstantX(config.rotationConstantX);
        setRotationConstantY(config.rotationConstantY);
        setRotationConstantZ(config.rotationConstantZ);
        setHex(config.backgroundColor);
        setRingHex(config.metalColor);
        setDiamondHex(config.diamondColor);
    }

    const getConfigs = async () => {
        try {
            const response = await fetch(`${BASE_URL}getConfigs`);
            if (!response.ok) {
                const { success, message } = await response.json();
                showAlert(message, success == "true" ? "lightgreen" : "lightcoral");
            }
            const data = await response.json();
            setConfigs(data);
        } catch (error) {
            showAlert("Something wrong happen while Fetching configurations! Try again after some time.", "lightcoral");
        }
        setShowConfigPopup(false);
    }

    // Local constants------------------------------------------------------------------------ 
    


    function resetControls() {
        setRotationConstantX(0);
        setRotationConstantY(0);
        setRotationConstantZ(0);
        setZoomConstant(0);
        setHex('#28282B');
        setRingHex('#c8c8c8');
        setDiamondHex('#FFFFFF');
        // setCPlaneRotationX(-1.5);
        // setCPlaneRotationY(0);
        // setCPlanePosition(-0.5);
        setPRotation([0, 0, 0]);
    }

    function setDefaultRotation(category) {
        if(category === 'Ring') {
            setCustomJewelRotationX(-0.125);
            setcustomDiamondRotationX(1.4457);
            // setcustomDiamondRotationX(-0.125);
        } 
        if(category === 'Earring') {
            setCustomJewelRotationX(-1.6);
            setcustomDiamondRotationX(-0.025);
            setCustomJewelPositionY(0.2);
        }

    }

    // API call to get glb file from the SERVER------------------------------------------------------------------------ 

    const fetch3dModel = async () => {
        try {
            const url = NEW_BASE_URL + `getFile?style_no=${styleNo}`;
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.arrayBuffer();
            const file = new Blob([data], { type: "model/gltf+binary" });
            setModelUrl(URL.createObjectURL(file));
        } catch (error) {
            return null;
        }
    };

    const getCategory = async () => {
        try {
            const url = NEW_BASE_URL + `getProductByStyleNo?style_no=${styleNo}`;
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            const categoryName = data['product']['collection']['category'].categoryName;
            setCategory(categoryName);
            setDefaultRotation(categoryName);
        } catch (error) {
            return null;
        }
    };

    const fetchHDRFile = async (filename) => {
        console.log('inside fetchHDRFile()--------------', filename);
        try {
            const url = NEW_BASE_URL + `hdr-files/${filename}`;
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.arrayBuffer();
            // console.log('hdr-file-data: ', data);
            const file = new Blob([data], { type: "image/vnd.radiance" });
            setCurrHDRFile(URL.createObjectURL(file));
        } catch (error) {
            return null;
        }
    };

    useEffect(() => {
        fetch3dModel();
        getCategory();
    }, []);

    const fetchMultiple3dModel = async (productName) => {
        try {
            const url = NEW_BASE_URL + `getFile?style_no=${productName}`;
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.arrayBuffer();
            const file = new Blob([data], { type: "model/gltf+binary" });
            setModelUrl(URL.createObjectURL(file));
        } catch (error) {
            return null;
        }
    };

    const fetchNext3dModel = async (productName) => {
        try {
            const url = NEW_BASE_URL + `getFile?style_no=${productName}`;
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.arrayBuffer();
            const file = new Blob([data], { type: "model/gltf+binary" });
            setNextModelUrl(URL.createObjectURL(file));
        } catch (error) {
            return null;
        }
    };


    useEffect(() => {
        if (!styleNo) {
            fetchMultiple3dModel(style_numbers[currentIndex - 1]);
            if(currentIndex < style_numbers.length) {
                fetchNext3dModel(style_numbers[currentIndex])
            }
        }
    }, [currentIndex])

    useEffect(() => {
        if (!styleNo) {
            if(configIndex.current > 0 && configs.length > 0 && configIndex.current <= configs.length) {
                setConfig(configs[configIndex.current - 1]);
            }
        }
    }, [configIndex.current])


    const getProducts = async (collection_name, type) => {
        if(type === 'image') {
            setImageGenerate(true);
            setVideoGenerate(false);
        }
        else {
            setImageGenerate(false);
            setVideoGenerate(true);
        }
        setIsGenerating(true);
        setCollectionName(collection_name);
        const selectedCollection = collection_name || 'Vida';
        try {
            const response = await fetch(`${BASE_URL}getAllProducts?collection_name=${selectedCollection}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const products = await response.json();
            let snArray = [];
            for (let product of products) {
                if (!product.isProcessed) {
                    continue;
                }
                snArray.push(product.productName);
            }
            setStyle_numbers(snArray);
            setCurrentIndex(1);
            if(configs.length > 0) {
                setConfig(configs[0]);
            }
        } catch (error) {
            console.error('Error during fetch:', error);
            throw error;
        }
    }

    const captureVideo = (style_number, config_name) =>  {
        setTimeout(() => {
            startRecording(style_number, config_name);
          }, 2000);
    }

    // For Video recording------------------------------------------------------------------------ 

    // const startRecording = (style_number, config_name) => {
    //     setIsRotate(true);
    //     const canvas = canvasRef.current;
    //     setIsBloom(false);
    //     chunksRef.current = [];
    //     if (canvas.captureStream) {
    //         const stream = canvas.captureStream();
    //         mediaRecorderRef.current = new MediaRecorder(stream);

    //         mediaRecorderRef.current.ondataavailable = (event) => {
    //             if (event.data && event.data.size > 0) {
    //                 chunksRef.current.push(event.data);
    //             }
    //         };
    //         mediaRecorderRef.current.start();
    //     }
    // };

    const startRecording = () => {
        setIsRotate(true);
        const canvas = canvasRef.current;
        setIsBloom(false);
        chunksRef.current = [];
        alert("Recording Started!")
        if (canvas.captureStream) {
          const stream = canvas.captureStream(60);
          const options = {
            mimeType: 'video/webm; codecs=vp9',  // or 'video/mp4'
            videoBitsPerSecond: 25000000,  // 5Mbps, adjust as needed
          };
          mediaRecorderRef.current = new MediaRecorder(stream, options);
          
          mediaRecorderRef.current.ondataavailable = (event) => {
            if (event.data && event.data.size > 0) {
              chunksRef.current.push(event.data);
            }
          };
          mediaRecorderRef.current.onstop = () => {
            const blob = new Blob(chunksRef.current, { type: 'video/webm' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.href = url;
            a.download = `${styleNo}.webm`;
            a.click();
            URL.revokeObjectURL(url);
          };
          mediaRecorderRef.current.start();
        }
      };
    

    // const stopRecording = () => {
    //     setIsRotate(false);
    //     if (mediaRecorderRef.current) {
    //         mediaRecorderRef.current.onstop = () => {
    //             const blob = new Blob(chunksRef.current, { type: 'video/webm' });
    //             const reader = new FileReader();
    //             reader.readAsDataURL(blob);
    //             reader.onloadend = () => {
    //                 const dataUrl = reader.result;
    //                 // Call your existing function to upload the video
    //                 uploadVideo(dataUrl, style_numbers[currentIndex - 1], configIndex.current < configs.length ? configs[configIndex.current-1] : configs[configs.length-1]);
    //             };
    //         };
    //         mediaRecorderRef.current.stop();
    //     }
    // };

    const stopRecording = () => {
        setIsBloom(true);
        alert("Recording Stopped!")
        if (mediaRecorderRef.current) {
          mediaRecorderRef.current.stop();
        }
      };

    // For Image capture------------------------------------------------------------------------ 

    const captureCanvas = (mode, style_no, config) => {
        const gl = meshGl.current;
        const scene = meshScene.current;
        const camera = meshCamera.current;
        if (gl && scene && camera) {
            gl.render(scene, camera)
            const dataUrl = gl.domElement.toDataURL('image/png')
            if (mode == 'down') {
                const link = document.createElement('a');
                link.href = dataUrl;
                link.download = `${styleNo}.png`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
            if (mode == 'up') {
                uploadImage(dataUrl, style_no, config)
            }
        }
    }

    // For Uploading the Image to SERVER------------------------------------------------------------------------ 

    const uploadImage = async (dataUrl, style_no, config) => {
        const collection_name = collectionName || 'Vida';
        const originalName = currProdHandle;
        const colors_map = {
            '#ebcf9a': 'YG',
            '#c3c3c3': 'P',
            '#c8c8c8': 'S',
            '#d8d8d8': 'WG',
            '#ebc4af': 'RG'
        };
        try {
            const response = await fetch(`${BASE_URL}uploadImg`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ dataUrl, originalName, collection_name, style_no, config, colors_map, 'user_name' : userData?.preferred_username }),
            });
            if (!response.ok) {
                throw new Error('Failed to upload image');
            }
            const result = await response.json();
            // console.log("Image uploaded");
        } catch (error) {
        }
    };

    const uploadVideo = async (dataUrl, style_no, config) => {
        const collection_name = collectionName || 'Vida';
        const originalName = currProdHandle;
        const colors_map = {
            '#ebcf9a': 'YG',
            '#c3c3c3': 'P',
            '#c8c8c8': 'S',
            '#d8d8d8': 'WG',
            '#ebc4af': 'RG'
        };
        try {
            const response = await fetch(`${BASE_URL}uploadVideo`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ dataUrl, originalName, collection_name, style_no, config, colors_map, 'user_name' : userData?.preferred_username }),
            });
            if (!response.ok) {
                throw new Error('Failed to upload video');
            }
            const result = await response.json();
            // console.log("Video uploaded");
        } catch (error) {
        }
    };


    useEffect(() => {
        if (!styleNo && isGenerating && imageGenerate) {
            const interval = setInterval(() => {
                if (configIndex.current < configs.length+1) {
                    if (style_numbers.length > 0 && configs.length > 0) {
                        // console.log('calling Image Capture', style_numbers[currentIndex - 1],configIndex.current < configs.length ? configs[configIndex.current-1] : configs[configs.length-1], configIndex.current, currentIndex);
                        
                        captureCanvas('up', style_numbers[currentIndex - 1], configIndex.current < configs.length ? configs[configIndex.current-1] : configs[configs.length-1]);
                    }
                    if(configIndex.current < configs.length) {
                        setConfig(configs[configIndex.current]);
                    }
                    configIndex.current += 1;
                }
                if (currentIndex < style_numbers.length && configIndex.current >= configs.length + 1) {
                    configIndex.current = 1;
                    setShowCanvas(true);
                    setCurrentIndex(prevIndex => prevIndex + 1);
                }
                else if (currentIndex > style_numbers.length) {
                    // console.log('interval cleared!');
                    clearInterval(interval);
                    setIsGenerating(false);
                }
                if (currentIndex >= style_numbers.length && configIndex.current >= configs.length + 1) setIsGenerating(false);
            }, 3000);
            return () => clearInterval(interval);
        }
    }, [currentIndex, style_numbers, configIndex]);


    const automatic_generation = (interval) => {
                if(RingRef.current && RingRef.current.rotation.y < 0.001) {
                        if (configIndex.current < configs.length + 1) {
                            if (style_numbers.length > 0 && configs.length > 0 && RingRef.current.rotation.y === 0) {

                                    captureVideo(style_numbers[currentIndex - 1], configIndex.current < configs.length ? configs[configIndex.current-1] : configs[configs.length-1])
                
                            }
                            if(configIndex.current < configs.length && !firstTime.current) {
                                setConfig(configs[configIndex.current]);
                            }
                        
                            if(!firstTime.current) {
                                configIndex.current += 1;
                            }
                            firstTime.current = false;
                        }
                        if (currentIndex < style_numbers.length && configIndex.current >= configs.length + 1) {
                            configIndex.current = 1;
                            setShowCanvas(true);
                            setCurrentIndex(prevIndex => prevIndex + 1);
                        }
                        else if (currentIndex > style_numbers.length) {
                            // console.log('interval cleared!');
                            interval != null && clearInterval(interval);
                            setIsGenerating(false);
                        }
                    if (currentIndex >= style_numbers.length && configIndex.current >= configs.length) setIsGenerating(false);
                }
        }


    useEffect(() => {
        if (!styleNo && isGenerating && videoGenerate) {
            // console.log('style_no', style_numbers[currentIndex-1]);
            const interval = setInterval(() => {
                automatic_generation(interval);
            }, 3000);
            return () => clearInterval(interval);
        }
    }, [currentIndex, style_numbers, configIndex, loading]);


    useEffect(() => {
        const handleBeforeUnload = (event) => {
            const confirmationMessage = 'Are you sure you want to leave this page?';
            event.preventDefault();
            event.returnValue = confirmationMessage;
            return confirmationMessage;
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);


    function Jewel(props) {
        const texture = useLoader(RGBELoader, `${process.env.PUBLIC_URL || ''}${hdr_env_diamond}`)
        useEffect(() => {
            return () => {
                diamonds.forEach(geometry => geometry.dispose());
            };
        }, [diamonds]);

        diamonds = [];
        const { scene, nodes: node } = useGLTF(modelUrl, true);
        if (currProdHandle === 'vida-3-dia') {
            baseNode.current = node;
        }

        const { gl, scene: threeScene, camera } = useThree();
        meshGl.current = gl;
        meshScene.current = threeScene;
        RingRef.current = threeScene;
        meshCamera.current = camera;

        scene.traverse((child) => {
            if (child.isMesh && (!child.name.startsWith("Diamond"))) {
                // child.material.metalness = 1;
                // child.material.roughness = 0.1375;
                child.material.metalness = 1;
                child.material.roughness = 0.08;
                child.material.color.set(ringHex);
                child.castShadow = true;
            }
            if (
                child.isMesh
                && (child.name.startsWith("Diamond"))) {
                child.geometry.computeVertexNormals();
                diamonds.push(child.geometry);
                child.castShadow = true;
                child.material.visible = false;
            }
        })
        // useFrame(() => {
        //     if (RingRef.current) {
        //         isRotate && (RingRef.current.rotation.y += 0.004);
        //         isRotateNeg && (RingRef.current.rotation.y -= 0.004);
        //         prevRotation.current = RingRef.current.rotation.y;
        //         if(RingRef.current && RingRef.current.rotation.y > 6.4) {
        //             stopRecording();
        //         }
        //         // if (RingRef.current && cubeCamera.current) {
        //         //     // RingRef.current.visible = false;
        //         //     cubeCamera.current.update(gl, threeScene);
        //         //     // RingRef.current.visible = true;
        //         // }
        //         // console.log(RingRef.current);
        //     }
        // });
        return <group ref={RingRef} castShadow>
            <primitive object={scene} {...props} castShadow/>
            {diamonds.map((item, index) => (
                <Diamond {...props} key={index}
                    rotation={[customDiamondRotationX + rotationConstantX, 0 + rotationConstantZ, 0 - rotationConstantY]}
                    // position={[0, 0.45, 0.5]}
                    scale={0.1001}
                    texture={texture}
                    color={diamondHex}
                    geometry={item} />
            ))}
        </group>
    }

    const logoutUser = () => {
        // auth.removeUser();
        auth.signoutRedirect();
    }

    function handleCRotationX(sign) {
        sign ==='plus' ? 
        setCPlaneRotationX(prev => prev + 0.0003) : 
        setCPlaneRotationX(prev => prev - 0.0003)
    }

    function handleRotationX(sign) {
        sign ==='plus' ? 
        setRotationConstantX(prev => prev + 0.03) : 
        setRotationConstantX(prev => prev - 0.03)
    }
    function handleRotationY(sign) {
        sign ==='plus' ? 
        setRotationConstantY(prev => prev + 0.03) : 
        setRotationConstantY(prev => prev - 0.03)
    }

    function handleCRotationY(sign) {
        sign ==='plus' ? 
        setCPlaneRotationY(prev => prev + 0.0003) : 
        setCPlaneRotationY(prev => prev - 0.0003)
    }

    function handleCPosition(sign) {
        sign ==='plus' ? 
        setCPlanePosition(prev => prev + 0.03) : 
        setCPlanePosition(prev => prev - 0.03)
    }

    const hasRole = (role) => {
        return userRoles.includes(role);
    }

    useEffect(() => {
        setCanCreate(hasRole("SUPER_ADMIN") || hasRole('ADMIN'));
        setCanEdit(hasRole("SUPER_ADMIN") || hasRole('ADMIN'));
        setCanDelete(hasRole("SUPER_ADMIN") || hasRole('ADMIN'));
    }, [userRoles])

    useEffect(() => {
        if (!auth.isAuthenticated && !auth.error && !auth.isLoading) {
          auth.signinRedirect();
        }
        if (auth.isAuthenticated) {
            // console.log("SHIVHSHIV auth", auth.user.profile);  
            setUserData(auth.user.profile);
        }
      }, [auth]);


    const handleFetchHDR = (filename) => {
        console.log('filename in', handleFetchHDR);
        setSelectedItem(`/hdr-files/${filename}`)
        fetchHDRFile(filename)
    }

    const HDREnvironment = () => {
        const { scene } = useThree();
        const hdrTexture = useLoader(RGBELoader, currHDRFile);
        // const hdrTexture = useLoader(RGBELoader, `${process.env.PUBLIC_URL || ''}/unity_env.hdr`);
        const pmremGenerator = new THREE.PMREMGenerator(useThree().gl);
        const envMap = pmremGenerator.fromEquirectangular(hdrTexture).texture;
        scene.environment = envMap;
        hdrTexture.dispose();
        pmremGenerator.dispose();
      
        return null;
      };

      const [hideToggle, setHideToggle] = useState(false)

    return (
        <div className={load4K ? 'Container4k' : 'Container'}>
            <div className={hideToggle ? 'hide-toggle hidden-toggle' : 'hide-toggle'}
                onClick={() => hideToggle ? setHideToggle(false) : setHideToggle(true)}>
                {!hideToggle ? <ChevronLeft /> : <ChevronRight />}
            </div>
            <Alerts />
            <div>
                <SaveConfigPopup show={showConfigPopup} setShow={setShowConfigPopup} 
                    saveConfig={saveConfig} 
                    currConfigId={currConfigId} 
                    currConfigName={currConfigName} 
                    currView={currView} 
                    updateConfig={updateConfig} />
                <ClosePopup show={showClosePopup} setShow={setShowClosePopup} />
                <ConfigListScreen disabled={isGenerating} configs={configs} setConfig={setConfig} 
                    deleteConfig={deleteConfig} canDelete={canDelete}/>
                
            </div>
                    <div className={hideToggle ? 'controls hidden' : 'controls'}>
                    <div className='wrapper'>
                    <div className='logout'>
                            <Button size="sm" variant="secondary" onClick={logoutUser}>LOGOUT</Button>
                            <Download className='rec-btn rec-img-btn' onClick={() => (captureCanvas('down'))} />
                            <Button size="sm" variant="secondary" onClick={() => load4K ? setLoad4K(false) : setLoad4K(true)}>
                                {load4K ? '4k' : 'Display'}
                            </Button>
                        </div>
                    <div className='detail'>
                        <div className='info-details'>
                            <div className='title'>
                                Config Name: {currConfigName}
                            </div>
                            <div className='title'>
                                Style No: {styleNo ? styleNo : style_numbers[currentIndex - 1]}
                            </div>
                            <div className='title'>
                                Category: {category ? category : null}
                            </div>
                        </div>
                    </div>
                    <hr className='border'/>
                        {!isGenerating && <div className='color-div'>
                            <span className='title main-title'>Colors</span>
                            <div>
                                <Colors setHex={setHex} title='Background' colors={bgColors} hex={hex} />
                                <span style={{
                                    color: "whitesmoke",
                                    fontSize: "12px"
                                }}>
                                    {hex === '#000000' ? "Transparent" : hex}
                                </span>
                            </div>
                            <div>
                                <Colors setHex={setRingHex} title='Metal' colors={ringColors} hex={ringHex} map={metal_colors_map}/>
                                <span style={{
                                    color: "whitesmoke",
                                    fontSize: "12px"
                                }}>
                                    {ringHex}
                                </span>
                            </div>
                            <div>
                                <Colors setHex={setDiamondHex} title='Diamond' colors={diamondColors} hex={diamondHex} map={diamond_colors_map}/>
                                <span style={{
                                    color: "whitesmoke",
                                    fontSize: "12px"
                                }}>
                                    {diamondHex}
                                </span>
                            </div>
                        </div>}
                        <hr className='border'/>
                        {!isGenerating && <div className='ring-controls'>
                            <span className='title main-title'>Orientation</span>
                            <div className='master-div'>
                                        <div className='rotation'> 
                                            <div className='rotation-div'>
                                                <span className='rotation-title title'>X-Rotation:</span>
                                                <div className='rotation-controls'>
                                                    <PlusCircle className='rec-btn' onClick={() => (
                                                        handleRotationX('plus')
                                                    )} />
                                                    <MinusCircle className='rec-btn' onClick={() => (
                                                        handleRotationX('minus')
                                                    )} />
                                                </div>
                                            </div>
                                            <input
                                                        type="range"
                                                        min="-360"
                                                        max="360"
                                                        step="0.01"
                                                        value={rotationConstantX}
                                                        onChange={(e) => setRotationConstantX(parseFloat(e.target.value))}
                                                    />
                                            <div className='rotation-div'>
                                                <span className='rotation-title title'>Y-Rotation:</span>
                                                <div className='rotation-controls'>
                                                    <PlusCircle className='rec-btn' onClick={() => (
                                                        handleRotationY('plus')
                                                    )} />
                                                    <MinusCircle className='rec-btn' onClick={() => (
                                                        handleRotationY('minus')
                                                    )} />
                                                </div>
                                            </div>
                                            <input
                                                        type="range"
                                                        min="-360"
                                                        max="360"
                                                        step="0.01"
                                                        value={rotationConstantY}
                                                        onChange={(e) => setRotationConstantY(parseFloat(e.target.value))}
                                                    />
                                            <div className='rotation-div'>
                                                <span className='rotation-title title'>C-RotationX</span>
                                                <div className='rotation-controls'>
                                                    <PlusCircle className='rec-btn' onClick={() => (
                                                        handleCRotationX('plus')
                                                    )} />
                                                    <MinusCircle className='rec-btn' onClick={() => (
                                                        handleCRotationX('minus')
                                                    )} />
                                                </div>
                                            </div>
                                            <input
                                                        type="range"
                                                        min="2.99"
                                                        max="3.01"
                                                        step="0.0003"
                                                        value={cPlaneRotationX}
                                                        onChange={(e) => setCPlaneRotationX(parseFloat(e.target.value))}
                                                    />
                                                    <div className='rotation-div'>
                                                <span className='rotation-title title'>C-RotationY</span>
                                            <div className='rotation-controls'>
                                                        <PlusCircle className='rec-btn' onClick={() => (
                                                            handleCRotationY('plus')
                                                        )} />
                                                        <MinusCircle className='rec-btn' onClick={() => (
                                                            handleCRotationY('minus')
                                                        )} />
                                                    </div>
                                                </div>
                                                <input
                                                            type="range"
                                                            min="1.58"
                                                            max="1.599"
                                                            step="0.0003"
                                                            value={cPlaneRotationY}
                                                            onChange={(e) => setCPlaneRotationY(parseFloat(e.target.value))}
                                                        />
                                            <div className='rotation-div'>
                                                <span className='rotation-title title'>C-Position:</span>
                                                <div className='rotation-controls'>
                                                    <PlusCircle className='rec-btn' onClick={() => (
                                                        handleCPosition('plus')
                                                    )} />
                                                    <MinusCircle className='rec-btn' onClick={() => (
                                                        handleCPosition('minus')
                                                    )} />
                                                </div>
                                            </div>
                                            <input
                                                        type="range"
                                                        min="15.1"
                                                        max="15.5"
                                                        step="0.001"
                                                        value={cPlanePosition}
                                                        onChange={(e) => setCPlanePosition(parseFloat(e.target.value))}
                                                    />
                                            
                                        </div>
                                    {/* <hr className='vertical-border' /> */}
                                        <div className='zoom'>
                                                <Button variant='dark' size='sm' className='' onClick={() => (
                                                            setZoomConstant(prev => prev + 0.3)
                                                        )}>Zoom In</Button>
                                                <Button variant='dark' size='sm' className='' onClick={() => (
                                                    setZoomConstant(prev => prev + -0.3)
                                                        )} >Zoom Out</Button>
                                                <Button variant='dark' disabled={isRotateNeg} size='sm' className='' onClick={() => (
                                                    isRotate ? setIsRotate(false) : setIsRotate(true)
                                                        )}>Spin+</Button>
                                                        <Button variant='dark' disabled={isRotate} size='sm' className='' onClick={() => (
                                                    isRotateNeg ? setIsRotateNeg(false) : setIsRotateNeg(true)
                                                        )}>Spin-</Button>
                                        </div>
                            </div>
                            </div>}

                            
                            <hr className='border'/>
                            <div className='env'>
                                <DropdownComponent handleFetchHDR={handleFetchHDR} 
                                                    items={hdrFiles} 
                                                    selectedItem={selectedItem} 
                                                    onChange={setSelectedItem} 
                                                    onDelete={handleDelete} 
                                                    showAlert={showAlert} 
                                                    fetchHdrFiles={fetchHdrFiles}
                                                    />

                                <UploadHDRFile fetchHdrFiles={fetchHdrFiles} showAlert={showAlert}/>
                            </div>
                            
                            {!isGenerating && <div className='save-download'>
                                <div className='reset'>
                                    <Sparkles className='rec-btn' onClick={() => isBloom ? setIsBloom(false) : setIsBloom(true)} />
                                </div>
                                <div className='download'>
                                    <Button variant='dark' size='sm' onClick={resetControls}>Reset</Button>
                                    <Button variant='dark' size='sm' onClick={startRecording}>Start</Button>
                                    <Button variant='dark' size='sm' onClick={stopRecording}>Stop</Button>
                                    {((!currConfigId && canCreate) || (currConfigId && canEdit)) && 
                                        <Button variant='dark' size='sm' onClick={() => { setShowConfigPopup(true); }} >Save</Button>}
                                </div>
                            </div>}
                            <hr className='border'/>
                            {!styleNo && <div style={{
                                    color: "white",
                                    fontSize: "12px"
                                }}>
                                <GenerateScreen isGenerating={isGenerating} 
                                                currentIndex={currentIndex} 
                                                style_numbers={style_numbers} 
                                                configIndex={configIndex.current} 
                                                configs={configs} 
                                                getProducts={getProducts} />
                            </div>}
                        </div>
                    </div>
            
            <Suspense fallback={<Loader />}>

                <Canvas
                    performance={{min: 0.1}} 
                    frameloop='demand' shadows gl={{ antialias: true }} 
                    camera={{ position: [0, 5, 8], far: 200, fov: 25, rotation: [3, 0 ,0] }} 
                    ref={canvasRef} className='canvas'>

                <Suspense fallback={<PercentageLoader />}>
                            <PresentationControls
                                enabled={false}
                                config={{ mass: 2, tension: 500 }}
                                // snap={{ mass: 4, tension: 1500 }}
                                rotation={[0, 0, 0]}
                                polar={[-Math.PI / 3, Math.PI / 3]}
                                azimuth={[-Math.PI / 1.4, Math.PI / 2]}>
                                {modelUrl && 
                                        <group position={[0, -0.25, 0]}>
                                                <Center top position={[0, 0, 0]} rotation={[0, 0, 0]}>
                                                
                                                    <Jewel
                                                        rotation={[customJewelRotationX + rotationConstantX, 0 + rotationConstantY, 0 + rotationConstantZ]}
                                                        position={[0, customJewelPositionY, 0 + zoomConstant]}
                                                        scale={100}
                                                        />
                                                </Center>

                                                <AccumulativeShadows temporal frames={100} color={'black'} opacity={0.7}>
                                                    <RandomizedLight radius={5} position={[10, 5, -2]} />
                                                </AccumulativeShadows>
                                            </group>
                                        }
                            </PresentationControls>
                    </Suspense>
                    <OrbitControls enableRotate={true} enablePan={false} rotation={pRotation} autoRotate={isRotate}/>
                    <Suspense fallback={<PercentageLoader />}>
                        {currHDRFile && <HDREnvironment />} 
                    </Suspense>
                    <CurvedPlane hex={hex} rotation={[cPlaneRotationX, 3, cPlaneRotationY]} position={[-30, cPlanePosition, -100]}/>
                </Canvas>
            </Suspense>
            <div className='logo'>
                <img src='/logo-white.png' alt='logo' />
            </div>
        </div>
    )
})

export default RenderScreen4;