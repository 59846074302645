import React, { useState, useEffect } from 'react';
import SearchableSelect from '../components/SearchableSelect';
import { BASE_URL } from '../lib/commons.js'
import { Button, Form, Spinner } from 'react-bootstrap';
import '../components/css/GenerateScreen.css';

const GenerateScreen = ({ isGenerating,  currentIndex, style_numbers, configIndex, configs, getProducts}) => {
    const [locations, setLocations] = useState([]);
    const [categories, setCategories] = useState([]);
    const [collections, setCollections] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedCollection, setSelectedCollection] = useState('');

    const [toggleAlert, setToggleAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const [alertColor, setAlertColor] = useState('green');

    const alertStyle = {
        position: "absolute",
        right: "0",
        left: "0",
        zIndex: "10",
        backgroundColor: alertColor
    }

    function Alerts() {
        return (
            <>
                {toggleAlert && <div className="alert" style={alertStyle}>{alertMessage}</div>}
            </>
        )
    }

    function showAlert(message, color, time = 3000) {
        setAlertMessage(message);
        setAlertColor(color);
        setToggleAlert(true);
        setTimeout(function () { setToggleAlert(false) }, time)
    }


    const handleSelectCollection = (option) => {
        setSelectedCollection(option);
    };

    const getCollections = async () => {
        try {
            const response = await fetch(`${BASE_URL}getCollectionOptions?location_name=${selectedLocation}&&category_name=${selectedCategory}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            let collectionOptions = [];
            for (let collection of data) {
                collectionOptions.push(collection.collectionName);
            }
            setCollections(collectionOptions);
        } catch (error) {
            console.error('Error during fetch:', error);
            throw error;
        }
    }

    useEffect(() => {
        getCollections();
    }, []);

    const generateImages = () => {
        if(selectedCollection)
        {
            getProducts(selectedCollection, 'image');
        }
    }

    const generateVideos = () => {
        if(selectedCollection)
        {
            getProducts(selectedCollection, 'video');
        }
    }

    return (
        <div className='generateScreen' >
            <div className='m-1' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <span className='title' style={{ textWrap: 'nowrap', fontSize: '16px' }}>Collection Name:&nbsp;&nbsp;</span>
                <SearchableSelect disabled={isGenerating} options={collections} selectedOption={selectedCollection} onSelect={handleSelectCollection} />
            </div>
            {!isGenerating ?  <div className='m-1' style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "10px" }}>
                <Button disabled={isGenerating} onClick={!isGenerating ? generateImages : null} size="sm" variant="dark">{isGenerating ? <Spinner animation="border" size="sm" /> : 'Generate Images'}</Button>
                <Button disabled={isGenerating} onClick={!isGenerating ? generateVideos : null} size="sm" variant="dark">{isGenerating ? <Spinner animation="border" size="sm" /> : 'Generate Videos'}</Button>
            </div> : <Spinner animation="border" size="sm" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "10px" }}/>}
            <div>
                {
                    isGenerating && style_numbers.length > 0 && configs.length > 0 && (
                        <>
                            <div>{currentIndex}/{style_numbers.length} Products Completed.</div>
                            <div>{configIndex}/{configs.length} images processed for the product {style_numbers[currentIndex-1]}.</div>
                        </>
                    )
                }
        </div>
        </div >
    )
}

export default GenerateScreen;